export default {
  welcomeMessage: "ยินดีต้อนรับ The Progressive JavaScript Framework",
  highlights: {
    approachable: "สวัสดี",
  },

  //op
  userInfo: "ข้อมูลผู้ใช้งาน",
  userBasicInfo: "ข้อมูลพื้นฐาน",
  company: "บริษัท",

  errors: {
    alpha: "{field} ต้องเป็นตัวอักษรเท่านั้น",
    alpha_dash:
      "{field} สามารถมีตัวอักษร ตัวเลข เครื่องหมายขีดกลาง (-) และเครื่องหมายขีดล่าง (_)",
    alpha_num: "{field} ต้องเป็นตัวอักษร และตัวเลขเท่านั้น",
    alpha_spaces: "{field} ต้องเป็นตัวอักษร และช่องว่างเท่านั้น",
    between: "{field} ต้องเป็นค่าระหว่าง 0:{min} และ 1:{max}",
    confirmed: "การยืนยันข้อมูลของ {field} ไม่ตรงกัน",
    digits: "{field} ต้องเป็นตัวเลขจำนวน 0:{length} หลักเท่านั้น",
    dimensions: "{field} ต้องมีขนาด 0:{width}x{height} px",
    email: "{field} ต้องเป็นรูปแบบอีเมล",
    excluded: "{field} ต้องเป็นค่าที่กำหนดเท่านั้น",
    ext: "{field} สกุลไฟล์ไม่ถูกต้อง",
    image: "{field} ต้องเป็นรูปภาพเท่านั้น",
    one_of: "{field} ต้องเป็นค่าที่กำหนดเท่านั้น",
    integer: "{field} ต้องเป็นเลขจำนวนเต็ม",
    length: "{field} ต้องมีความยาว 0:{length}",
    max: "{field} ต้องมีความยาวไม่เกิน {length} ตัวอักษร",
    max_value: "{field} ต้องมีค่าไม่เกิน {max}",
    mimes: "{field} ประเภทไฟล์ไม่ถูกต้อง",
    min: "{field} ต้องมีความยาวอย่างน้อย {length} ตัวอักษร",
    min_value: "{field} ต้องมีค่าตั้งแต่ {min} ขึ้นไป",
    numeric: "{field} ต้องเป็นตัวเลขเท่านั้น",
    regex: "รูปแบบ {field} ไม่ถูกต้อง",
    required: "กรุณากรอก {field}",
    required_if: "กรุณากรอก {field}",
    size: "{field} ต้องมีขนาดไฟล์ไม่เกิน 0:{size}KB",
    match: "ข้อมูลของ {field} ไม่ตรงกัน",

    statusUnauthorized: "Username หรือ Password ไม่ถูกต้อง!",
    statusUnauthorizedUsername: "Username หรือ Device ไม่ถูกต้อง!",
    statusUnauthorizedPassword: "Password ไม่ถูกต้อง!",
  },

  //labels
  users: {
    address: "ที่อยู่",
    address_1: "ที่อยู่ 1",
    address_2: "ที่อยู่ 2",
    city: "อำเภอ",
    province: "จังหวัด",
    zipcode: "รหัสไปรษณีย์",
    email: "Email Address",
    password: "Password",
    repeatPassword: "Repeat Password",
  },

  //status
  Ready: "Ready: พร้อมเริ่มจับคู่ Station 2",
  Mprostart: "Start: อยู่ระหว่างการดำเนินการ Station 2",
  Mprofinish: "Finish: ดำเนินการเสร็จสิ้น Station 2",
  Mlotno: "กำลังบรรจุ Station 3",
  Activated: "Activated: พร้อมใช้งาน",
  Clear: "Clear: ล้างข้อมูลเรียบร้อยแล้ว",
  Deleted: "Delete: ลบข้อมูลเรียบร้อยแล้ว",
  Empty: "Empty: Tag เปล่า",

  FirstName: "ชื่อ",
  ScanTagBarcode: "แสกน Tag Barcode",
  SelectProduct: "เลือกสินค้า (Product)",
  TagCode: "Tag Code",
  ProductID: "Product ID",

  ScanQrCode: "แสกน QR Code",
  SelectTable: "เลือกโต๊ะ (Table)",
  SelectDestination: "เลือกจุดหมาย (Destination)",
  PleaseSelectTable: "โปรดเลือกโต๊ะ",
  PleaseSelectProduct: "โปรดเลือกสินค้า",
  PleaseSelectDestination: "โปรดเลือกจุดหมาย",

  // Language: javascript (Thesis)
  formThesisID: "แก้ไข ข้อมูลงานวิทยานิพนธ์",
  thesis: "ข้อมูลงานวิทยานิพนธ์",
  thesis1: "ข้อมูลงานวิทยานิพนธ์",
  thesis2: "ข้อมูลงานวิทยานิพนธ์",
  examples: "Examples",
  educations: "งานการศึกษา",
  research: "ระบบงานวิจัย",
  researchType: "ประเภทการวิจัย",
  researchType2: "ประเภทการวิจัย",
  formResearchType: "แก้ไข ประเภทการวิจัย",
  researchData: "ข้อมูลงานวิจัย",
  researchData2: "ข้อมูลงานวิจัย",
  formResearchID: "แก้ไข ข้อมูลงานวิจัย",
  academicWork: "ผลงานวิชาการ",
  academicWork2: "ผลงานวิชาการ",
  formAcademicWorkID: "แก้ไข ผลงานวิชาการ",
  academicService: "ระบบงานบริการวิชาการ",
  professionalService: "การปฏิบัติการบริการวิชาชีพ",
  professionalService2: "การปฏิบัติการบริการวิชาชีพ",
  formProfessionalServiceID: "แก้ไข การปฏิบัติการบริการวิชาชีพ",
  mediaPublishing: "การปฏิบัติการเผยแพร่ผ่านสื่อ",
  mediaPublishing2: "การปฏิบัติการเผยแพร่ผ่านสื่อ",
  formMediaPublishingID: "แก้ไข การปฏิบัติการเผยแพร่ผ่านสื่อ",
  goAbroad: "ไปต่างประเทศ",
  goAbroad2: "ไปต่างประเทศ",
  formGoAbroadID: "แก้ไข ไปต่างประเทศ",
  meeting: "การจัดประชุม",
  meeting2: "การจัดประชุม",
  formMeetingID: "แก้ไข การจัดประชุม",

  // Language: javascript (Login)
  dashboards: "Dashboards",

  // Language: javascript (old)
  english: "EN",
  thai: "TH",
  title: "สวัสดี {0}!",
  msg: "ทดสอบข้อความ",
  notAutorized: "ไม่มีสิทธิ์ในการเข้าถึง",
  confirmDeleteTitle: "ยืนยัน ลบข้อมูล!",
  confirmDelete: "ลบ! กรุณายืนยันลบข้อมูล?",
  titleNotification: "กรุณาให้ความสนใจ",
  usersTable: "ข้อมูลผู้ใช้งาน",
  search: "ค้นหา",
  add: "เพิ่ม",
  edit: "แก้ไข",
  back: "ย้อนกลับ",
  user: "ผู้ใช้งาน",
  no: "ที่",

  lastName: "นามสกุล",
  username: "Username",
  status: "สถานะ",
  password: "รหัสผ่าน",
  save: "Save", //'บันทึก',
  saveAllData: "Save All", //บันทึกข้อมูลทั้งหมด', //'บันทึก',
  saveChange: "Save", //'บันทึกการแก้ไข',
  cancel: "Cancel", //'ยกเลิก',
  close: "Close", //'ปิด',
  yesConfirmDelete: "ลบ! ยืนยันลบข้อมูลได้เลย",
  saveAlready: "บันทึกข้อมูลเรียบร้อยแล้ว!",
  deleteAlready: "ลบข้อมูลเรียบร้อยแล้ว!",
  cancelAlready: "ยกเลิกข้อมูลเรียบร้อยแล้ว!",
  oopsError: "ขออภัย.. การทำงานผิดพลาด..",
  oopsPleaseSelectQuestion: "โปรดเลือกคำถามอย่างน้อย 1 คำถาม",
  toastedErrorType: "error",
  loginSuccess: "Login Success!",
  groupCode: "รหัสกลุ่มสิทธิ์",
  groupName: "ชื่อกลุ่มสิทธิ์",
  companyCode: "รหัสบริษัท",
  companyName: "ชื่อบริษัท",
  remark: "หมายเหตุ ",
  statusIMUsed: "ไม่สามารถลบข้อมูลได้ ข้อมูลถูกนำไปใช้งานแล้ว!",
  StatusForbiddenDuplicate: "ไม่สามารถบันทึกข้อมูลได้ ข้อมูลซ้ำ!",
  StatusForbiddenOverLimit: "ไม่สามารถบันทึกข้อมูลได้ จำนวนผู้ใช้งานเต็มแล้ว!",
  statusCannotPrintReceipt:
    "ไม่สามารถพิมพ์ใบเสร็จได้! เนื่องจากไม่ได้บันทึกข้อมูลใบเสร็จไว้ ...",
  code: "รหัส",
  nameTh: "ชื่อ (Th)",
  nameEn: "ชื่อ (En)",
  createDate: "วันที่สร้าง",
  courseCategory: "หมวดหมู่คอร์ส",
  course: "คอร์ส",
  coursePath: "เส้นทาง",
  courseLevelID: "Course Level",
  hour: "ชั่วโมง",
  minute: "นาที",
  orderNo: "ลำดับ",
  // statusUnauthorized: 'Username หรือ Password ไม่ถูกต้อง!',
  // statusUnauthorizedUsername: 'Username ไม่ถูกต้อง!',
  // statusUnauthorizedPassword: 'Password ไม่ถูกต้อง!',
  statusPetrolPerLitterNotCorrect:
    "ข้อมูลอัตรา(บาท/ลิตร) ไม่ถูกต้อง! ในการนำส่ง 1 ครั้งอัตราน้ำมันแต่ละชนิดจะต้องเท่ากัน! กรุณาตรวจสอบ",
  oopsErrorDocumentNoDup:
    "ขออภัย.. ไม่สามารถแก้ไขเลขที่เอกสารได้! เลขที่เอกสารที่ใช้ได้ต้องเป็นเลขที่เอกสารที่ถูกลบไปเท่านั้น",

  //menu
  systemMenu: "ข้อมูลระบบ",
  companyMenu: "ข้อมูลบริษัท",
  userMenu: "ข้อมูลผู้ใช้งาน",
  authGroupMenu: "ข้อมูลกลุ่มผู้ใช้งานระบบ",
  masterCourseMenu: "ข้อมูลหลักคอร์ส",
  categoryMenu: "ข้อมูลหมวดหมู่",
  categoryItemMenu: "ข้อมูลรายการหมวดหมู่ย่อย",
  pathMenu: "ข้อมูลเส้นทาง",
  pathItemMenu: "ข้อมูลรายการเส้นทางย่อย",
  levelMenu: "ข้อมูลระดับ",
  courseMenu: "ข้อมูลคอร์ส",

  examMasterMenu: "ข้อมูลหลักข้อสอบ",
  examBankMenu: "คลังข้อสอบ",
  examDifficultyLevelMenu: "ระดับความยากง่ายข้อสอบ",
  examRelateQuestionMenu: "ความสัมพันธ์ของคำถาม",
  examQuestionMenu: "คำถามข้อสอบ",
  examAnswerTypeMenu: "ประเภทคำตอบข้อสอบ",
  examAnswerChoiceMenu: "ตัวเลือกคำตอบข้อสอบ",
  examAnswerExplainMenu: "คำอธิบายคำตอบข้อสอบ",

  examMenu: "ข้อมูลข้อสอบ",
  examCourseMenu: "ข้อมูลคอร์สข้อสอบ",
  examCourseQuestionMenu: "ข้อมูลคำตอบในคอร์สข้อสอบ",
  examTransactionMenu: "ข้อมูลการสอบ",
  examTransactionItemMenu: "ข้อมูลรายการย่อยการสอบ",

  remarkTh: "หมายเหตุ (Th)",
  remarkEn: "หมายเหตุ (En)",
  questionTh: "คำถาม (Th)",
  questionEn: "คำถาม (En)",
  answer: "คำตอบ",
  answerTh: "คำตอบ (Th)",
  answerEn: "คำตอบ (En)",
  examBankTh: "ชุดข้อสอบ (Th)",
  examBankEn: "ชุดข้อสอบ (En)",
  btnConfirmImportExam: "ยืนยัน เริ่มใส่ข้อมูลคำถามข้อสอบ",
  examCourse: "ชุดข้อสอบ",

  //dialog box message
  cancelTitle: "คุณแน่ใจมั๊ย?",
  cancelText: "คุณจะไม่สามารถแก้ไข 'การยกเลิกรายการ' ได้นะ!",
  cancelConfirmButtonText: "Confirm, ยกเลิกรายการ!",
  closeButtonText: "Close",

  //dialog box message
  deleteTitle: "คุณแน่ใจมั๊ย?",
  deleteText: "คุณจะไม่สามารถยกเลิกข้อมูลการลบได้นะ!",
  // deleteConfirmButtonText: "ใช่, ลบเลย!",
  deleteConfirmButtonText: "Confirm, delete!",
  startQuestionConfirmButtonText: "ใช่, เริ่มเลือกคำถามเลย!",

  statusTitle: "คุณแน่ใจมั๊ย?",
  statusText: "คุณต้องการยืนยันแก้ไขสถานะนะ!",
  statusConfirmButtonText: "ใช่, แก้ไขสถานะเลย!",
  saveTitle: "คุณแน่ใจมั๊ย?",
  saveText: "คุณต้องการยืนยันบันทึกข้อมูลนะ!",
  saveConfirmButtonText: "Confirm, save!",

  saveAllTitle: "คุณแน่ใจมั๊ย?",
  saveAllText: "คุณจะไม่สามารถยกเลิกข้อมูลการบันทึกได้นะ!",
  saveAllConfirmButtonText: "Confirm, save all!",
  oopsErrorDebitCreditPetrolAmount:
    "ขออภัย.. ไม่สามารถบันทึกข้อมูลได้! ยอดเงินข้อมูลน้ำมันไม่เท่ากับยอด เดบิต/เครดิต!  กรุณาตรวจสอบอีกครั้ง",
  oopsErrorDebitCredit:
    "ขออภัย.. ไม่สามารถบันทึกข้อมูลได้! ยอดเงินเดบิต/เครดิตจะต้องเท่ากัน และมีค่ามากกว่า 0!  กรุณาตรวจสอบอีกครั้ง",
  oopsErrorDebitCreditPostGL:
    "ขออภัย.. ไม่สามารถ Post รายการได้! ยอดเงินเดบิต/เครดิตจะต้องเท่ากัน และมีค่ามากกว่า 0!  กรุณาตรวจสอบอีกครั้ง",
  oopsErrorDateRequired:
    "ขออภัย.. ไม่สามารถบันทึกข้อมูลได้! โปรดเลือกวันที่โอน",
  oopsErrorAmountZero:
    "ขออภัย.. ไม่สามารถบันทึกข้อมูลได้! ยอดเงินต้องมีค่ามากกว่า 0",
  oopsErrorPaymentNumberNull:
    "ขออภัย.. ไม่สามารถบันทึกข้อมูลได้! กรุณาบันทึกข้อมูล Payment Number",
  oopsErrorFineAmountNull:
    "ขออภัย.. ไม่สามารถบันทึกข้อมูลได้! กรุณากรอกข้อมูลค่าปรับ",
  oopsErrorDateAdjutmentChequeRequired:
    "ขออภัย.. ไม่สามารถบันทึกข้อมูลได้! โปรดเลือกวันที่ปรับปรุงเช็ค",

  cannotPostTitle: "ไม่สามารถ Post รายการนี้ได้! ",
  cannotPostTitleNumber: "ไม่สามารถ Post รายการนี้ได้! เลขที่เอกสาร: ",
  cannotPostText: "เงื่อนไขการ Post - ยอด Dr/Cr ต้องเท่ากัน และมากกว่า 0",
  // cannotPostText: "ขออภัย.. ไม่สามารถบันทึกข้อมูลได้! ยอดเงินเดบิต/เครดิตจะต้องเท่ากัน และมีค่ามากกว่า 0!  กรุณาตรวจสอบอีกครั้ง!",

  savePostAllTitle: "คุณแน่ใจมั๊ย?",
  savePostAllText: "คุณจะไม่สามารถยกเลิกข้อมูลการ Post GL ได้นะ!",
  savePostAllConfirmButtonText: "Confirm, save all!",

  oopsErrorPaymentAmountOverLimit:
    "ขออภัย.. ไม่สามารถบันทึกข้อมูลได้! ยอดเงินตั้งหนี้จะต้องไม่เกินยอดเงินในงวดโครงการ และมีค่ามากกว่า 0!  กรุณาตรวจสอบอีกครั้ง",
  oopsErrorRequiredData:
    "ขออภัย.. ไม่สามารถบันทึกข้อมูลได้! กรุณากรอกข้อมูลที่มีเครื่องหมาย (*) ให้ครบ!",

  saveCancelPostAllTitle: "คุณแน่ใจมั๊ย?",

  saveCancelPostAllText:
    "คุณจะไม่สามารถยกเลิกข้อมูลการ 'ยกเลิก Post GL' ได้นะ!",
  saveCancelPostAllConfirmButtonText: "Confirm, ยกเลิก Post GL!",
  saveApproveAllText: "คุณจะไม่สามารถยกเลิกการอนุมัติได้นะ!",
  saveNotApproveAllText: "ยืนยัน! ไม่อนุมัติ!",
  saveNotApproveAllTitle: "ไม่อนุมัติรายการ?",

  //New July 2020
  //start routes
  Home: "Home",
  MenuSetting: "ตั้งค่าระบบ",
  SystemUserTables: "ตั้งค่าข้อมูลผู้ใช้",
  // SystemUserTables: 'สิทธิ์การใช้งาน - ตารางตั้งค่าข้อมูลผู้ใช้',
  FormSystemUsers: "เพิ่มข้อมูลผู้ใช้",
  FormSystemUsersID: "แก้ไขข้อมูลผู้ใช้",
  PermissionUser1: "สิทธิ์การใช้งาน",
  PermissionUser2: "สิทธิ์การใช้งาน",
  PermissionUser3: "สิทธิ์การใช้งาน",
  Main: "Main",
  PermissionGroup1: "สิทธิ์การใช้งาน", //no english
  PermissionGroup2: "สิทธิ์การใช้งาน",
  PermissionGroup3: "สิทธิ์การใช้งาน",
  GroupTables: "ข้อมูลสิทธิ์การเข้าระบบ",
  FormGroup: "เพิ่มสิทธิ์การเข้าระบบ (กลุ่มผู้ใช้งาน)",
  FormGroupID: "แก้ไขสิทธิ์การเข้าระบบ (กลุ่มผู้ใช้งาน)",
  GroupTablesMain: "กลุ่มผู้ใช้งานระบบ",
  PermissionGroupUser1: "สิทธิ์การใช้งาน - ข้อมูลสิทธิ์การเข้าระบบ", //no english
  PermissionGroupUser2: "สิทธิ์การใช้งาน - ข้อมูลสิทธิ์การเข้าระบบ",
  PermissionGroupUser3: "สิทธิ์การใช้งาน - ข้อมูลสิทธิ์การเข้าระบบ",
  GroupUsersTablesID: "ข้อมูลผู้ใช้งานในกลุ่ม",
  FormGroupUsers: "เพิ่มผู้ใช้งานในกลุ่ม",
  FormGroupUsersID: "แก้ไขผู้ใช้งานในกลุ่ม",
  PermissionGroupMenu: "สิทธิ์การใช้งาน - ข้อมูลสิทธิ์การเข้าระบบ",
  GroupMenuTablesID: "กำหนดสิทธิ์การเข้าถึงเมนู",
  PermissionLogin: "สิทธิ์การใช้งาน",
  SystemLogin: "ตั้งค่าข้อมูลการเข้าใช้ระบบ",
  SystemPrefixTables: "คำนำหน้าชื่อ",
  SystemPrefix: "คำนำหน้าชื่อ",
  SystemGeneralInformation: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation2: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation3: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation4: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation5: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation6: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation7: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation8: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation9: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation10: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation11: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation12: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation13: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation14: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation15: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation16: "ข้อมูลทั่วไประบบ",
  SystemGeneralInformation17: "ข้อมูลทั่วไประบบ",
  SystemMenuTables: "ข้อมูล Menu",
  PermissionMenu: "สิทธิ์การใช้งาน",
  PermissionMenu2: "สิทธิ์การใช้งาน",
  PermissionMenu3: "สิทธิ์การใช้งาน",
  FormSysMenus: "เพิ่มเมนู",
  FormSysMenusID: "แก้ไขเมนู",
  FormSystemPrefixes: "เพิ่มคำนำหน้าชื่อ",
  FormSystemPrefixesID: "แก้ไขคำนำหน้าชื่อ",
  SystemUnitTables: "หน่วยนับ",
  FormSystemUnits: "เพิ่มหน่วยนับ",
  FormSystemUnitsID: "แก้ไขหน่วยนับ",
  SystemProvinceTables: "จังหวัด",
  FormSystemProvinces: "เพิ่มจังหวัด",
  FormSystemProvincesID: "แก้ไขจังหวัด",
  SystemGeneralCompany1: "ข้อมูลทั่วไปบริษัท",
  SystemGeneralCompany2: "ข้อมูลทั่วไปบริษัท",
  SystemGeneralCompany3: "ข้อมูลทั่วไปบริษัท",
  SystemGeneralCompany4: "ข้อมูลทั่วไปบริษัท",
  SystemGeneralCompany5: "ข้อมูลทั่วไปบริษัท",
  SystemGeneralCompany6: "ข้อมูลทั่วไปบริษัท",
  SystemGeneralCompany7: "ข้อมูลทั่วไปบริษัท",
  SystemGeneralCompany8: "ข้อมูลทั่วไปบริษัท",
  SystemGeneralCompany9: "ข้อมูลทั่วไปบริษัท",
  SystemGeneralCompany10: "ข้อมูลทั่วไปบริษัท",
  SystemGeneralCompany11: "ข้อมูลทั่วไปบริษัท",
  SystemGeneralCompany12: "ข้อมูลทั่วไปบริษัท",
  SystemCompanyTables: "ข้อมูลบริษัท",
  FormSystemCompanies: "เพิ่มข้อมูลบริษัท",
  FormSystemCompaniesID: "แก้ไขข้อมูลบริษัท",
  SystemDepartmentTables: "ข้อมูลหน่วยงาน",
  FormSystemDepartments: "เพิ่มหน่วยงาน",
  FormSystemDepartmentsID: "แก้ไขหน่วยงาน",
  SystemPositionTables: "ข้อมูลตำแหน่งงาน",
  FormSystemPositions: "เพิ่มตำแหน่งงาน",
  FormSystemPositionsID: "แก้ไขตำแหน่งงาน",
  SystemEmployeeTables: "ข้อมูลพนักงาน",
  FormSystemEmployees: "เพิ่มข้อมูลพนักงาน",
  FormSystemEmployeesID: "แก้ไขข้อมูลพนักงาน",
  BudgetSetting: "ตั้งค่างบประมาณ",
  BudgetYearTables: "ปีงบประมาณ",
  FormBudgetYears: "เพิ่มปีงบประมาณ",
  FormBudgetYearsID: "แก้ไขปีงบประมาณ",
  MoneySourceTables: "แหล่งเงิน",
  FormMoneySources: "เพิ่มแหล่งเงิน",
  FormMoneySourcesID: "แก้ไขแหล่งเงิน",
  ExpenseCategoryTables: "หมวดรายจ่าย",
  FormExpenseCategories: "เพิ่มหมวดรายจ่าย",
  FormExpenseCategoriesID: "แก้ไขหมวดรายจ่าย",
  AccPeriodTables: "งวดบัญชี",
  FormAccPeriods: "เพิ่มงวดบัญชี",
  FormAccPeriodsID: "แก้ไขงวดบัญชี",
  AccountSetting: "ตั้งค่าข้อมูลบัญชี",
  ArSetup1: "AR Setup",
  ArSetup2: "AR Setup",
  ArSetup3: "AR Setup",
  ArSetup4: "AR Setup",
  ArSetup5: "AR Setup",
  ArSetup6: "AR Setup",
  ArSetup7: "AR Setup",
  ArSetup8: "AR Setup",
  ArSetup9: "AR Setup",
  DebtorTables: "ลูกหนี้",
  FormsDebtors: "เพิ่มลูกหนี้",
  FormsDebtorsID: "แก้ไขลูกหนี้",
  SystemCustomerPrefixTables: "คำนำหน้าชื่อ เจ้าหนี้/ลูกหนี้",
  FormSystemCustomerPrefixes: "เพิ่มคำนำหน้าชื่อ เจ้าหนี้/ลูกหนี้",
  FormSystemCustomerPrefixesID: "แก้ไขคำนำหน้าชื่อ เจ้าหนี้/ลูกหนี้",
  ApSetup1: "AP Setup",
  ApSetup2: "AP Setup",
  ApSetup3: "AP Setup",
  CreditorTables: "เจ้าหนี้",
  FormsCreditors: "เพิ่มเจ้าหนี้",
  FormsCreditorsID: "แก้ไขเจ้าหนี้",
  AccTaxTables: "ภาษี",
  taxSetup1: "Tax Setup",
  taxSetup2: "Tax Setup",
  taxSetup3: "Tax Setup",
  taxSetup4: "Tax Setup",
  taxSetup5: "Tax Setup",
  taxSetup6: "Tax Setup",
  taxSetup7: "Tax Setup",
  taxSetup8: "Tax Setup",
  taxSetup9: "Tax Setup",
  taxSetup10: "Tax Setup",
  taxSetup11: "Tax Setup",
  taxSetup12: "Tax Setup",
  FormsAccTaxes: "เพิ่มภาษี",
  FormsAccTaxesID: "แก้ไขภาษี",

  //menus
  AccWhtTables: "ภาษีหัก ณ ที่จ่าย",
  FormAccWht: "เพิ่ม ภาษีหัก ณ ที่จ่าย",
  FormAccWhtID: "แก้ไข ภาษีหัก ณ ที่จ่าย",
  AccRevenueTables: "รายได้",
  FormAccRevenues: "เพิ่ม รายได้",
  FormAccRevenuesID: "แก้ไข รายได้",
  AccExpensesTables: "ค่าใช้จ่าย",
  FormAccExpenses: "เพิ่ม ค่าใช้จ่าย",
  FormAccExpensesID: "แก้ไข ค่าใช้จ่าย",
  MenuGlSetting: "ตั้งค่า GL",
  GlGroupTables: "กลุ่ม GL",
  FormGlGroups: "เพิ่ม กลุ่ม GL",
  FormGlGroupsID: "แก้ไข กลุ่ม GL",
  GlRunningNumberTables: "เลขที่เอกสาร GL",
  FormGlRunningNumbers: "แก้ไข เลขที่เอกสาร GL",
  GlAccountChartTables: "รหัสบัญชี",
  FormGlAccountCharts: "เพิ่ม รหัสบัญชี",
  FormGlAccountChartsID: "แก้ไข รหัสบัญชี",
  GlAccLinkTables: "เอกสารเชื่อม GL",
  GlAccLinkChartTablesID: "รูปแบบบัญชีเชื่อม GL",
  FormGlAccLinkCharts: "เพิ่ม รูปแบบบัญชีเชื่อม GL",
  FormGlAccLinkChartsID: "แก้ไข รูปแบบบัญชีเชื่อม GL",
  FinancialStatementTables: "ตั้งค่างบการเงิน",
  FormFinancialStatements: "เพิ่ม ตั้งค่างบการเงิน",
  FormFinancialStatementsID: "แก้ไข ตั้งค่างบการเงิน",
  FinancialStatementFormatTables: "ตั้งค่างบการเงิน - รูปแบบงบการเงิน",
  FormFinancialStatementFormats: "เพิ่ม รูปแบบงบการเงิน",
  FormFinancialStatementFormatsID: "แก้ไข รูปแบบงบการเงิน",
  MenuChequeBankSetting: "ตั้งค่า Cheque & Bank",
  BankTables: "ธนาคาร",
  FormBanks: "เพิ่ม ธนาคาร",
  FormBanksID: "แก้ไข ธนาคาร",
  BankTypeTables: "ประเภทธนาคาร",
  FormBankTypes: "เพิ่ม ประเภทธนาคาร",
  FormBankTypesID: "แก้ไข ประเภทธนาคาร",
  BankAccountTables: "บัญชีธนาคาร",
  FormBankAccounts: "เพิ่ม บัญชีธนาคาร",
  FormBankAccountsID: "แก้ไข บัญชีธนาคาร",
  ChequeFormatTables: "รูปแบบเช็ค (แยกธนาคาร)",
  FormChequeFormats: "เพิ่ม รูปแบบเช็ค",
  FormChequeFormatsID: "แก้ไข รูปแบบเช็ค",
  MenuApproveSetting: "ตั้งค่า Approve",
  ApprovalAuthorityTables: "ผู้อนุมัติ",
  FormApprovalAuthorities: "เพิ่ม ผู้อนุมัติ",
  FormApprovalAuthoritiesID: "แก้ไข ผู้อนุมัติ",
  ApprovalRuleTables: "การอนุมัติ",
  FormApprovalRules: "เพิ่ม รายการอนุมัติ",
  FormApprovalRulesID: "แก้ไข รายการอนุมัติ",
  SubstituteApproverTables: "ผู้อนุมัติแทน",
  FormSubstituteApprovers: "เพิ่ม ผู้อนุม้ติแทน (ต่อคน)",
  FormSubstituteApproversID: "แก้ไข ผู้อนุม้ติแทน (ต่อคน)",
  StrategyPlanTables: "แผนยุทธศาสตร์",
  FormStrategyPlans: "เพิ่ม แผนยุทธศาสตร์",
  FormStrategyPlansID: "แก้ไข แผนยุทธศาสตร์",
  StrategyTables: "ยุทธศาสตร์",
  FormStrategies: "เพิ่ม ยุทธศาสตร์",
  FormStrategiesID: "แก้ไข ยุทธศาสตร์",
  WorkPlanTables: "แผนงาน",
  FormWorkPlans: "เพิ่ม แผนงาน",
  FormWorkPlansID: "แก้ไข แผนงาน",
  WorkGroupTables: "กลุ่มงาน",
  FormWorkGroups: "เพิ่ม กลุ่มงาน",
  FormWorkGroupsID: "แก้ไข กลุ่มงาน",
  StrategyPlanYearTables: "แผนยุทธศาสตร์ประจำปีงบประมาณ",
  FormStrategyPlanYears: "เพิ่ม แผนยุทธศาสตร์ประจำปีงบประมาณ",
  FormStrategyPlanYearsID: "แก้ไข แผนยุทธศาสตร์ประจำปีงบประมาณ",
  BudgetAllocationTables: "จัดสรรงบประมาณ",
  FormBudgetAllocations: "เพิ่ม จัดสรรงบประมาณ",
  FormBudgetAllocationsID: "แก้ไข จัดสรรงบประมาณ",
  MenuBudget: "งบประมาณ",
  MenuProject: "โครงการ",
  ProjectTables: "ข้อมูลโครงการ (ภายนอก)",
  FormProjects: "เพิ่ม ข้อมูลโครงการ (ภายนอก)",
  FormProjectsID: "แก้ไข ข้อมูลโครงการ (ภายนอก)",
  MenuFinanceReceive: "การเงินรับ (AR)",
  PetrolTypeTables: "ข้อมูลประเภทน้ำมัน",
  FormPetrolTypes: "เพิ่ม ข้อมูลประเภทน้ำมัน",
  FormPetrolTypesID: "แก้ไข ข้อมูลประเภทน้ำมัน",
  ArReceivePetrolTables: "รับเงินน้ำมัน",
  FormArReceivePetrols: "เพิ่ม รับเงินน้ำมัน",
  FormArReceivePetrolsID: "แก้ไข รับเงินน้ำมัน",
  ArCreditNonStockTables: "ตั้งหนี้ลูกหนี้",
  FormArCreditNonStocks: "เพิ่ม ตั้งหนี้ลูกหนี้",
  FormArCreditNonStocksID: "แก้ไข ตั้งหนี้ลูกหนี้",
  ArReceiptVoucherTables: "รับเงินอื่นๆ",
  FormArReceiptsVoucher: "เพิ่ม รับเงินอื่นๆ",
  FormArReceiptsVoucherID: "แก้ไข รับเงินอื่นๆ",
  ArReceiptWithoutCreditTables: "รับชำระ (ไม่ตั้งหนี้)",
  FormArReceiptWithoutCredits: "เพิ่ม รับชำระ (ไม่ตั้งหนี้)",
  FormArReceiptWithoutCreditsID: "แก้ไข รับชำระ (ไม่ตั้งหนี้)",
  ArAccountReceiveBalanceTables: "รายได้รอการตรวจสอบยกมา",
  FormArAccountReceiveBalances: "เพิ่ม รายได้รอการตรวจสอบยกมา",
  FormArAccountReceiveBalancesID: "แก้ไข รายได้รอการตรวจสอบยกมา",
  ArReceiveProjectTables: "รับเงินโครงการ",
  FormArReceiveProjects: "เพิ่ม รับเงินโครงการ",
  FormArReceiveProjectsID: "แก้ไข รับเงินโครงการ",
  PertrolCompanyTables: "ข้อมูลบริษัทผู้ผลิต/นำส่งน้ำมัน",
  FormPertrolCompanies: "เพิ่ม ข้อมูลบริษัทผู้ผลิต/นำส่งน้ำมัน",
  FormPertrolCompaniesID: "แก้ไข ข้อมูลบริษัทผู้ผลิต/นำส่งน้ำมัน",
  MainProjectTables: "ข้อมูลหลักโครงการ (ภายนอก)",
  FormMainProjects: "เพิ่ม ข้อมูลหลักโครงการ (ภายนอก)",
  FormMainProjectsID: "แก้ไข ข้อมูลหลักโครงการ (ภายนอก)",
  JournalEntryBatchTables: "รายการรายวัน (Batch Post GL)",
  FormJournalEntryBatch: "เพิ่ม รายการรายวัน (Batch Post GL)",
  FormJournalEntryBatchID: "แก้ไข รายการรายวัน (Batch Post GL)",
  FormJournalEntryBatchFlowID: "ข้อมูลรายละเอียด รายการรายวัน",
  JournalEntryTables: "รายการรายวัน",
  FormJournalEntry: "เพิ่ม รายการรายวัน",
  FormJournalEntryID: "แก้ไข รายการรายวัน",
  MenuGL: "บัญชี (GL)",
  MenuReportReceive: "รายงานด้านรับ",
  report: "รายงาน",
  FormRptArReceiveAll: "รายงานการรับเงิน",
  FormRptDebtors: "รายงานการ์ดลูกหนี้",
  FormRptReceipts: "รายงานใบเสร็จรับเงิน",
  FormRptJournal: "รายงานข้อมูลรายวัน",
  MenuReportGL: "รายงานบัญชี (GL)",
  FormRptPetrolDetails: "รายงานรายได้จากผู้ผลิต - ผู้นำเข้า (ละเอียด)",
  FormRptPetrolSummary: "รายงานรายได้จากผู้ผลิต - ผู้นำเข้า (สรุป)",
  ApPayableVoucherTables: "ตั้งเจ้าหนี้ทั่วไป",
  FormApPayableVoucher: "เพิ่ม ตั้งเจ้าหนี้ทั่วไป",
  FormApPayableVoucherID: "แก้ไข ตั้งเจ้าหนี้ทั่วไป",
  MenuFinancePayment: "การเงินจ่าย (AP)",
  ApPaymentTables: "จ่ายชำระ (จากการตั้งหนี้)",
  FormApPayments: "เพิ่ม จ่ายชำระ (จากการตั้งหนี้)",
  FormApPaymentsID: "แก้ไข จ่ายชำระ (จากการตั้งหนี้)",
  ApPaymentNoDebtTables: "จ่ายชำระ (ไม่ตั้งหนี้)",
  FormApPaymentNoDebts: "เพิ่ม จ่ายชำระ (ไม่ตั้งหนี้)",
  FormApPaymentNoDebtsID: "แก้ไข จ่ายชำระ (ไม่ตั้งหนี้)",
  ApProjectTables: "ตั้งเจ้าหนี้โครงการ",
  FormApProjects: "เพิ่ม ตั้งเจ้าหนี้โครงการ",
  FormApProjectsID: "แก้ไข ตั้งเจ้าหนี้โครงการ",
  MenuReportWht: "รายงานภาษี",
  RptWhtTables: "หนังสือรับรองหัก ณ ที่จ่าย",
  FormRptSumWht: "รายงานสรุปภาษีหัก ณ ที่จ่าย",

  ApCreditorBeginningBalanceTables: "เจ้าหนี้ยกมา (ทั่วไป)",
  FormApCreditorBeginningBalance: "เพิ่ม เจ้าหนี้ยกมา (ทั่วไป)",
  FormApCreditorBeginningBalanceID: "แก้ไข เจ้าหนี้ยกมา (ทั่วไป)",

  ApCreditorProjectBeginningBalanceTables: "เจ้าหนี้ยกมา (โครงการ)",
  FormApCreditorProjectBeginningBalance: "เพิ่ม เจ้าหนี้ยกมา (โครงการ)",
  FormApCreditorProjectBeginningBalanceID: "แก้ไข เจ้าหนี้ยกมา (โครงการ)",

  MenuReportPayment: "รายงานการเงินจ่าย (AP)",
  FormRptApProjects: "รายงานตั้งหนี้เจ้าหนี้โครงการ",
  FormRptApPayableVoucher: "รายงานตั้งหนี้เจ้าหนี้ทั่วไป",
  FormRptApPayments: "รายงานจ่ายชำระ",
  FormRptApPaymentNoDebts: "รายงานจ่ายชำระ (ไม่ตั้งหนี้)",
  FormRptCreditors: "รายงานการ์ดเจ้าหนี้",
  FormRptEstimatePayments: "รายงานประมาณการจ่ายเงิน",
  FromRptCreditorBeginningBalance: "รายงานเจ้าหนี้ยกมา (ทั่วไป)",
  FromRptCreditorProjectBeginningBalance: "รายงานเจ้าหนี้ยกมา (โครงการ)",

  MenuChequeBank: "Cheque & Bank",
  MenuChequeReceive: "เช็ครับ (CR)",
  MenuChequePayment: "เช็คจ่าย (CP)",
  ChequePaymentTables: "เช็คจ่าย",
  FormChequePayments: "เพิ่ม/แก้ไข  เช็คจ่าย",
  MenuBankAdjustments: "Bank Adjustments",
  BankTransferTables: "โอนเงิน",
  FormBankTransfers: "แก้ไข โอนเงิน",
  FormBankTransfersID: "แก้ไข โอนเงิน",

  BankIncomeTables: "รายได้จากธนาคาร",
  BankExpensesTables: "ค่าใช้จ่ายธนาคาร",
  Bank1: "Bank Adjustments",
  Bank2: "Bank Adjustments",
  Bank3: "Bank Adjustments",
  Bank4: "Bank Adjustments",
  Bank5: "Bank Adjustments",
  Bank6: "Bank Adjustments",
  Bank7: "Bank Adjustments",
  Bank8: "Bank Adjustments",
  Bank9: "Bank Adjustments",
  Bank10: "Bank Adjustments",
  Bank11: "Bank Adjustments",
  Bank12: "Bank Adjustments",
  Bank13: "Bank Adjustments",
  Bank14: "Bank Adjustments",
  Bank15: "Bank Adjustments",
  Bank16: "Bank Adjustments",
  Bank17: "Bank Adjustments",
  Bank18: "Bank Adjustments",
  Bank19: "Bank Adjustments",
  Bank20: "Bank Adjustments",
  BankTransferAdjustmentTables: "ปรับปรุงรายการโอนเงิน",
  FormBankTransferAdjustmentsID: "บันทึกปรับปรุงรายการโอนเงิน",

  FormChequePaymentsID: "เช็คจ่าย",
  Cheque1: "เช็คจ่าย (CP)",
  Cheque2: "เช็คจ่าย (CP)",
  Cheque3: "เช็คจ่าย (CP)",
  Cheque4: "เช็คจ่าย (CP)",
  Cheque5: "เช็คจ่าย (CP)",
  Cheque6: "เช็คจ่าย (CP)",
  ChequePaymentAdjustmentTables: "ปรับปรุงเช็คจ่าย",
  FormChequePaymentAdjustmentsID: "บันทึกปรับปรุงเช็คจ่าย",

  FormBankDeposits: "เพิ่ม ฝากเงินสด",
  FormBankDepositsID: "แก้ไข ฝากเงินสด",
  BankWithdrawTables: "ถอนเงินสด",
  FormBankWithdraws: "เพิ่ม ถอนเงินสด",
  FormBankWithdrawsID: "แก้ไข ถอนเงินสด",
  BankTransferBetweenBookTables: "โอนเงินระหว่างสมุดเงินฝาก",
  FormBankTransferBetweenBooks: "เพิ่ม โอนเงินระหว่างสมุดเงินฝาก",
  FormBankTransferBetweenBooksID: "แก้ไข โอนเงินระหว่างสมุดเงินฝาก",
  FormBankIncomes: "เพิ่ม รายได้จากธนาคาร",
  FormBankIncomesID: "แก้ไข รายได้จากธนาคาร",

  // FormBankExpenses:'เพิ่ม ค่าใช้จ่ายธนาคาร',
  // FormBankExpensesID:'แก้ไข ค่าใช้จ่ายธนาคาร',

  FormBankExpenses: "เพิ่ม ค่าใช้จ่ายธนาคาร",
  FormBankExpensesID: "แก้ไข ค่าใช้จ่ายธนาคาร",
  MenuUser: "User",
  FormChangePasswordID: "แก้ไขรหัสผ่าน",
  FormChangePassword: "รหัสผ่าน",
  AccBeginningBalanceTables: "ยอดยกมา",
  FormAccBeginningBalance: "เพิ่ม ยอดยกมา",
  FormAccBeginningBalanceID: "แก้ไข ยอดยกมา",
  FormJournalEntryManual: "เพิ่ม รายการรายวัน",
  FormJournalEntryManualID: "แก้ไข รายการรายวัน",
  BankDepositTables: "ฝากเงินสด",
  DocumentNumberTables: "เลขที่ Running",
  FormDocumentNumbersID: "แก้ไข เลขที่ Running",
  MenuApproveCenter: "Approve Center",
  FormApproveCenter: "หน้าหลัก",

  FormRptJournalSummary: "สรุปข้อมูลรายวัน",
  FormRptLedgerAccount: "บัญชีแยกประเภท",
  FormRptLedgerAccountSummary: "บัญชีแยกประเภท - สรุปประจำเดือน",
  FormRptAccountDetails: "รายละเอียดประกอบบัญชี",
  FormRptAccountBeginningBalance: "ยอดยกมา",
  FormRptTrialBalance: "งบทดลอง",
  FormRptWorkSheets: "กระดาษทำการ",
  FormRptCompareBalanceQuarters: "เปรียบเทียบยอดคงเหลือ (ไตรมาส)",
  FormRptStatementOfFinancialPosition: "งบแสดงฐานะการเงิน",
  FormRptProfitAndLoss: "รายงานผลการดำเนินงาน",
  MenuReportBank: "รายงานธนาคาร",
  FormRptBankDeposit: "รายงานฝากเงิน",
  FormRptBankWithdraw: "รายงานถอนเงิน",
  FormRptBankTransfer: "รายงานโอนเงินระหว่างสมุดเงินฝาก",
  FormRptBankTransferOut: "รายงานโอนเงินออก",
  FormRptBankIncome: "รายงานรายได้จากธนาคาร",
  FormRptBankExpenses: "รายงานค่าใช้จ่ายธนาคาร",
  FormRptBankBalance: "รายงานยอดคงเหลือ",
  FormRptBankStatement: "รายงาน Statement",
  MenuReportCheque: "รายงานเช็ค",
  FormRptChequePayment: "รายงานเช็คจ่าย",
  FormRptChequePaymentByStatus: "รายงานเช็คจ่ายตามสถานะ",
  MenuReportProject: "รายงานงบประมาณ/โครงการ",
  FormRptProjectSummary: "รายงานข้อมูลโครงการ (ภาพรวม)",
  FormRptProjectSummaryWithTransaction: "รายงานสรุปการจ่ายเงินโครงการ (ภาพรวม)",
  FormRptProjectDetailWithTransaction:
    "รายงานสถานะการเบิกจ่ายโครงการ (ทะเบียนคุม)",
  FormRptAllocatedBudget: "รายงานงบประมาณประจำปี",
  FormRptAllocatedBudgetByCategories: "รายงานงบประมาณประจำปี แยกหมวดรายจ่าย",
  FormRptReceiptAndPaymentSummary: "รายงานสรุป รับ-จ่าย (เสนอผู้บริหาร )",
  FormRptExpensesMonthly: "รายงานรายจ่ายประจำเดือน",
  FormRptReceiptAndPayment: "รายงานการรับ - จ่ายเงิน",
  MenuReportSetting: "รายงานตั้งค่าทั่วไป",
  LogTables: "รายงาน Log การใช้งานระบบ",
  FormLogsID: "รายละเอียด Log การใช้งานระบบ",

  // TableSystemUsers: 'สิทธิ์การใช้งาน - ตารางตั้งค่าข้อมูลผู้ใช้',
  // FormSystemUsers: 'สิทธิ์การใช้งาน - ฟอร์มเพิ่มข้อมูลผู้ใช้',
  // FormSystemUsersID: 'สิทธิ์การใช้งาน - ฟอร์มแก้ไขข้อมูลผู้ใช้',

  //end routes

  //start header page Add/Edit
  headerFormSystemUsers: "เพิ่ม-แก้ไข ข้อมูลผู้ใช้",
  headerAddPermisionGroupUsers: "เพิ่มผู้ใช้งานในกลุ่ม",
  // headerFormGroups: 'เพิ่ม-แก้ไข ข้อมูลกลุ่มผู้ใช้งาน',
  // headerAddGroups: 'เพิ่มข้อมูลกลุ่มผู้ใช้งาน',
  //end header page Add/Edit

  //Button
  ButtonAdd: "New",
  ButtonClose: "Close",
  ButtonDelete: "Delete",
  ButtonResetExpireDate: "Reset Expire Date",
  ButtonReset: "Reset",
  ButtonExport: "Export",
  ButtonExportExcel: "Export Excel",

  //Another
  lblUsers: "ชื่อผู้ใช้งาน",
  age: "อายุ",
  noIndex: "ลำดับ",
  isActive: "Is Active",
  showing: "Showing",
  to: "to",
  of: "of",
  entries: "entries",
  rows: "rows",
  selected: "selected",

  manageUsers: "จัดการผู้ใช้งาน",
  manageMenus: "จัดการเมนู",
  companySelect: "เลือกบริษัท",
  appNotes: "ชื่อเมนู",
  appID: "ID",
  appLabel: "ป้ายชื่อ (AppLabel map กับ routes)",
  appModel: "โมเดล (AppModel)",
  appLevel: "ระดับ (AppLevel)",
  appParentID: "Parent (AppParentID)",
  appOrder: "ลำดับ (AppOrder)",
  appIcon: "Icon (AppIcon)",
  prefixCode: "รหัส",
  prefixShortNameTh: "ชื่อย่อคำนำหน้าชื่อ (Th)",
  prefixShortNameEn: "ชื่อย่อคำนำหน้าชื่อ (En)",
  prefixNameTh: "ชื่อคำนำหน้าชื่อ (Th)",
  prefixNameEn: "ชื่อคำนำหน้าชื่อ (En)",
  unitCode: "รหัส",
  unitShortNameTh: "ชื่อย่อหน่วยนับ (Th)",
  unitShortNameEn: "ชื่อย่อหน่วยนับ (En)",
  unitNameTh: "ชื่อหน่วยนับ (Th)",
  unitNameEn: "ชื่อหน่วยนับ (En)",
  provinceCode: "รหัส",
  provinceShortNameTh: "ชื่อย่อจังหวัด (Th)",
  provinceShortNameEn: "ชื่อย่อจังหวัด (En)",
  provinceNameTh: "ชื่อจังหวัด (Th)",
  provinceNameEn: "ชื่อจังหวัด (En)",
  deepSearch: "Filter", //'ค้นหาแบบละเอียด',
  chooseSelect: "เลือก",
  btnSearch: "Search",
  btnClear: "Clear",
  btnClose: "Close",
  companyShortNameTh: "ชื่อย่อบริษัท (Th)",
  companyShortNameEn: "ชื่อย่อบริษัท (En)",
  companyNameTh: "ชื่อบริษัท (Th)",
  companyNameEn: "ชื่อย่อบริษัท (En)",
  companyTaxNumber: "เลขประจำตัวผู้เสียภาษี",
  companyAddress: "ที่อยู่บริษัท",
  companyPhone: "เบอร์โทรศัพท์",
  companyFax: "เบอร์แฟ็กซ์",
  companyEmail: "อีเมล์",
  companyWebsite: "เว็บไซต์",
  departmentCode: "รหัส",
  departmentShortNameTh: "ชื่อย่อหน่วยงาน (Th)",
  departmentShortNameEn: "ชื่อย่อหน่วยงาน (En)",
  departmentNameTh: "ชื่อหน่วยงาน (Th)",
  departmentNameEn: "ชื่อหน่วยงาน (En)",
  departmentType: "ประเภท",
  departmentInternal: "ภายใน",
  departmentExternal: "ภายนอก",
  positionCode: "รหัสตำแหน่ง",
  positionShortNameTh: "ชื่อย่อตำแหน่ง (Th)",
  positionShortNameEn: "ชื่อย่อตำแหน่ง (En)",
  positionNameTh: "ชื่อตำแหน่ง (Th)",
  positionNameEn: "ชื่อตำแหน่ง (En)",
  employeeCode: "รหัสพนักงาน",
  firstNameTh: "ชื่อ (Th)",
  lastNameTh: "นามสกุล (Th)",
  firstNameEn: "ชื่อ (En)",
  lastNameEn: "นามสกุล (En)",
  masPrefixID: "คำนำหน้าชื่อ",
  prefixSelect: "เลือกคำนำหน้าชื่อ",
  masPositionID: "ตำแหน่งงาน",
  masDepartmentID: "หน่วยงาน",
  fullNameTh: "ชื่อ-สกุล",
  budgetYear: "ปีงบประมาณ",
  startDate: "วันที่เริ่มต้น",
  endDate: "วันที่สิ้นสุด",
  moneySourceCode: "รหัสแหล่งเงิน",
  moneySourceNameTh: "ชื่อแหล่งเงิน (Th)",
  moneySourceNameEn: "ชื่อแหล่งเงิน (En)",
  isShowMenu: "แสดงในแถบเมนูใช่หรือไม่?",
  isShowMenuLabel: "Is Menu",
  expenseCategoryCode: "รหัสหมวดรายจ่าย",
  expenseCategoryNameTh: "ชื่อหมวดรายจ่าย (Th)",
  expenseCategoryNameEn: "ชื่อหมวดรายจ่าย (En)",
  categoryType: "ประเภทหมวดรายจ่าย",
  categoryTypeParent: "หมวดรายจ่ายหลัก",
  generateData: "Generate",
  accPeriodNo: "งวดที่",
  daysAmount: "วัน",
  daysAmountText: "จำนวนวัน",
  accPeriodStatus: "สถานะ",
  accPeriodStatusIsOpen: "สถานะ Open",
  customerCode: "รหัสลูกค้า",
  customerNameTh: "ชื่อลูกค้า",
  debtorCode: "รหัสลูกหนี้",
  debtorNameTh: "ชื่อลูกหนี้",
  debtorFullNameTh: "รหัส-ชื่อลูกหนี้",
  creditorCode: "รหัสเจ้าหนี้",
  creditorNameTh: "ชื่อเจ้าหนี้",
  customerNameBillingTh: "ชื่อลูกค้าออกบิล",
  faxNumber: "แฟ็กซ์",
  actionStatus: "สถานะลูกค้า",
  isOneTimeCustomer: "Is One-Time Customer",
  masPrefixCustomerID: "ประเภท",
  email: "Email",
  lineID: "Line ID",
  facebook: "Facebook",
  website: "เว็บไซต์",

  companyType: "ประเภทบริษัท",
  detail: "รายละเอียด (Detail)",
  branchNumber: "สาขาที่",
  registrationNumber: "เลขที่จดทะเบียน",
  registeredCapital: "ทุนจดทะเบียน",
  // numberOfEmployees:'จำนวนพนักงาน',
  // income:'รายได้',
  customerType: "ประเภทลูกค้า",
  taxpayerNumber: "เลขที่ผู้เสียภาษี",
  accTaxID: "ภาษี",
  businessType: "ประเภทธุรกิจ",
  customerSource: "แหล่งที่มาลูกค้า",
  customerBirthday: "วันเกิดลูกค้า",
  // accReceiveableID:'บัญชีลูกหนี้',
  credit: "เครดิต (Credit)",
  debit: "เดบิต (Debit)",
  creditLimit: "วงเงินเครดิต",
  creditDays: "จำนวนวันเครดิต",
  discountEndBill: "ส่วนลดท้ายบิล",
  addressType: "ประเภทที่อยู่",
  addressTypeRq: "ประเภทที่อยู่ *",
  addressName: "ชื่อที่อยู่",
  postalCode: "รหัสไปรษณีย์",
  masProvinceName: "จังหวัด",
  isDefault: "ค่าเริ่มต้น",
  subDistrict: "แขวง/ตำบล",
  district: "เขต/อำเภอ",
  masCountryID: "ประเทศ",
  contactNameTh: "ชื่อผู้ติดต่อ",
  telephoneNumber: "เบอร์โทรศัพท์",
  contactPositionNameTh: "ตำแหน่งงาน",
  isMainContact: "ผู้ติดต่อหลัก",
  accountCode: "รหัสบัญชี",
  vendorCode: "รหัสผู้ขาย",
  vendorNameTh: "ชื่อผู้ขาย",
  isOneTimeVendor: "Is One-Time Vendor",
  vendorType: "ประเภทผู้ขาย",
  accTaxCode: "รหัสภาษี",
  accTaxNameTh: "ชื่อภาษี",
  // accTaxNameEn:'ชื่อภาษี (En)',
  accTaxStatusCode: "ภาษีซื้อ/ขาย",
  accTaxTypeID: "ประเภทภาษี",
  accTaxRate: "อัตราภาษี",

  whtCode: "รหัสภาษีหัก ณ ที่จ่าย",
  taxRate: "อัตราภาษี (%)",
  masRevenueID: "ชื่อประเภทรายได้",
  revenueCode: "รหัสรายได้",
  revenueNameTh: "ชื่อรายได้",
  expensesCode: "รหัสค่าใช้จ่าย",
  expensesNameTh: "ชื่อค่าใช้จ่าย",
  accTypeCode: "รหัสกลุ่ม GL",
  accTypeNameTh: "ชื่อกลุ่ม GL",
  accountNameTh: "ชื่อบัญชี",
  glGroupID: "กลุ่ม GL",
  accountType: "ประเภทบัญชี",
  accountLevel: "ระดับ",
  normalBalance: "ยอดคงเหลือปกติ Dr/Cr",
  accountControlCode: "รหัสบัญชีคุม",
  financialSheet: "Financial",
  gfmisCode: "รหัส GFMIS",
  isCfs: "ใช้ใน CFS",
  isNbms: "ใช้ใน NBMS",
  masExpenseCategoryID:
    "หมวดรายจ่าย (งบประมาณ - สำหรับแสดงรายงานตามหมวดรายจ่าย)",
  isBalanceSheet: "รูปแบบงบแสดงฐานะการเงิน",
  isIncomeStatement: "รูปแบบงบดำเนินงาน",
  isCashFlowStatement: "ใช้ในงบกระแสเงินสด",
  thisCompanyName: "ระบบการบริหารจัดการเงินกองทุน (ส.กทอ.)",
  thisCompanyNameShort: "ส.กทอ.",
  menuName: "เมนู",
  formatCode: "รหัสรูปแบบ",
  formatName: "ชื่อรูปแบบ",
  formatDescription: "คำอธิบาย",
  accJournalBookID: "สมุดรายวัน",
  isLinkAccChart: "เชื่อมโยงผังบัญชี",
  linkOtherLabel: "ชื่ออ้างอิง",
  linkOtherCode: "รหัสรายการลงบัญชี", //'รหัสอ้างอิงอื่น (ไม่เชื่อมโยงผังบัญชีโดยตรง)',
  accountList: "รายการบัญชี",
  recordType: "ประเภทบัญชี",
  isLinkAccChartStatus: "สถานะเชื่อมโยงผังบัญชี",
  isLinkAccChartYes: "ใช่, เชื่อมโยงผังบัญชี",
  isLinkAccChartNo: "ไม่ใช่, ไม่เชื่อมโยงผังบัญชี",
  accountCodeName: "รหัส-ชื่อบัญชี",
  linkOtherDescription: "คำอธิบายรหัสอ้างอิง",
  bankCode: "รหัสธนาคาร",
  bankNameTh: "ชื่อธนาคาร",
  bankShortNameTh: "ชื่อย่อธนาคาร",
  bankTypeCode: "รหัสประเภทบัญชีธนาคาร",
  bankTypeNameTh: "ชื่อประเภทบัญชีธนาคาร",
  bankAccountCode: "รหัสสมุดเงินฝาก",
  bankAccountNameTh: "ชื่อบัญชีธนาคาร",
  bankBookNumber: "เลขที่สมุดงินฝาก",
  masBankBranchID: "ข้อมูลสาขา",
  masBankBranchAndBankID: "ข้อมูลธนาคาร และสาขา",
  masBankTypeID: "ประเภทธนาคาร",
  MasBank1: "ธนาคาร",
  MasBank2: "ธนาคาร",
  MasBank3: "ธนาคาร",
  BankBranchTables: "สาขาธนาคาร",
  FormBankBranches: "เพิ่ม สาขาธนาคาร",
  FormBankBranchesID: "แก้ไข สาขาธนาคาร",
  branchCode: "รหัสสาขา",
  branchNameTh: "ชื่อสาขา",
  branchAddress: "ที่อยู่",
  module: "Module",
  fieldName: "ชื่อฟิลด์",
  topicName: "ชื่อหัวข้อ",
  statusCode: "รหัสสถานะ",
  nextStatusCode: "รหัสสถานะถัดไป",
  nextStatusCodeDisapprove: "รหัสสถานะถัดไป(ไม่อนุมัติ)",
  isEditable: "สามารถแก้ไขข้อมูลได้",
  isEditableTrue: "แก้ไขได้?",
  statusName: "ชื่อสถานะ",
  nextStatusName: "ชื่อสถานะถัดไป",
  nextStatusNameDisapprove: "ชื่อสถานะถัดไป(ไม่อนุมัติ)",
  employeeCodeFullName: "รหัส- ชื่อพนักงาน",
  rules1: "การอนุมัติ",
  rules2: "การอนุมัติ",
  rules3: "การอนุมัติ",
  GlAccJournalBookTables: "สมุดรายวัน",
  FormGlAccJournalBooks: "เพิ่ม สมุดรายวัน",
  FormGlAccJournalBooksID: "แก้ไข สมุดรายวัน",
  bookCode: "รหัสสมุดรายวัน",
  bookNameTh: "ชื่อสมุดรายวัน",
  bookShortNameTh: "ชื่อย่อสมุดรายวัน",
  strategyPlanCode: "รหัสแผนยุทธศาสตร์",
  strategyPlanShortNameTh: "ชื่อย่อแผนยุทธศาสตร์",
  strategyPlanNameTh: "ชื่อแผนยุทธศาสตร์",
  createdAt: "วันที่สร้าง",
  updatedAt: "วันที่แก้ไขล่าสุด",
  strategyCode: "รหัสยุทธศาสตร์",
  strategyNameTh: "ชื่อยุทธศาสตร์",
  masStrategyPlanID: "แผนยุทธศาสตร์",
  workPlanCode: "รหัสแผนงาน",
  workPlanNameTh: "ชื่อแผนงาน",
  masWorkPlanID: "แผนงาน",
  strategyFullNameTh: "ยุทธศาสตร์",
  masStrategyID: "ยุทธศาสตร์",
  workGroupCode: "รหัสกลุ่มงาน",
  workGroupNameTh: "ชื่อกลุ่มงาน",
  WorkPlan1: "แผนงาน/กลุ่มงาน",
  WorkPlan2: "แผนงาน/กลุ่มงาน",
  WorkPlan3: "แผนงาน/กลุ่มงาน",
  budgetNameTh: "ชื่องบประมาณ",
  workPlanFullNameTh: "แผนงาน",
  workGroupFullNameTh: "กลุ่มงาน",
  moneySourceFullNameTh: "แหล่งเงิน",
  expenseCategoryFullNameTh: "หมวดรายจ่าย",
  approvedBudget: "เงินทีได้รับอนุมัติ",
  baht: "บาท",
  petrolTypeCode: "รหัสชนิดน้ำมัน",
  petrolTypeNameTh: "ชื่อชนิดน้ำมัน",
  documentNumber: "เลขที่เอกสาร",
  customerID: "รหัสลูกค้า",
  documentDate: "วันที่เอกสาร",
  customerContactID: "ผู้ติดต่อ",
  invoiceNumber: "เลขที่ใบกำกับ",
  invoiceDate: "วันที่ใบกำกับ",
  appointmentPaymentDate: "วันที่นัดชำระ",
  sumAmount: "รวมเงิน",
  tradeDiscount: "ส่วนลดการค้า",
  cutDeposit: "ตัดเงินมัดจำ",
  amountBeforeTax: "เงินก่อนหักภาษี",
  TaxBaseAmount: "ฐานภาษี",
  taxAmount: "ภาษี (บาท)",
  totalAmount: "จำนวนเงินทั้งสิ้น",
  remeainingAmount: "จำนวนเงินคงเหลือ", //ใช้ตอนรับคงเหลือ
  bankFee: "ค่าธรรมเนียมธนาคาร", //ใช้ในการ รับเงินค่าน้ำมัน
  petrolSendType: "ส่งเงินจาก", //กรมสรรพสามิต/กรมสรรพากร
  billingAddress: "ที่อยู่ออกบิล",
  note: "Note",
  isNoteShowInForm: "ตั้งเป็นหมายเหตุที่แสดงในท้ายฟอร์ม",
  arDataStatus: "สถานะเอกสาร", //Open/Close/Cancel/Partial
  docType: "ประเภทเอกสาร",
  approvalStatusCode: "สถานะการอนุมัติ",
  approvalAuthUserID: "ผู้อนุมัติ",
  approvalDate: "วันที่อนุมัติ",
  referenceBookNumber: "เลขที่หนังสืออ้างอิง",
  referenceBookDate: "วันที่หนังสืออ้างอิง",
  customerCodeName: "รหัส-ชื่อลูกค้า",
  gfmisNameTh: "ชื่อบัญชี GFMIS",
  revenueTypeCodeName: "ประเภทรายได้",
  connextWithRevenueExpenses: "เชื่อมโยงรายได้/ค่าใช้จ่าย?",
  revenue: "รายได้",
  expenses: "ค่าใช้จ่าย",
  noConnext: "ไม่เชื่อมโยง",
  yesConnextPlan: "ใช่! เชื่อมโยงแผนงาน กลุ่มงาน",
  isConnectPlanGroup: "เชื่อมโยงแผนงาน กลุ่มงาน?",
  projectCode: "รหัสโครงการ",
  projectNameTh: "ชื่อโครงการ",
  confirmationNumber: "เลขที่หนังสือยืนยัน",
  customerProject: "ผู้ได้รับการสนับสนุน",
  confirmationDate: "ลงวันที่",
  confirmationBudget: "วงเงินตามหนังสือยืนยัน",
  projectStartDate: "วันที่เริ่มโครงการ",
  projectEndDate: "วันที่สิ้นสุดโครงการ",
  projectExtendDate: "วันที่ขยายเวลาโครงการ (ล่าสุด)",
  projectStatus: "สถานะโครงการ",
  projectID: "โครงการ",
  periodNo: "งวดที่",
  periodAmount: "จำนวนเงิน",
  periodDate: "วันที่",
  periodStatus: "สถานะการจ่ายเงิน",
  amountMoney: "จำนวนเงิน",
  projectFull: "โครงการ ผู้ได้รับการสนับสนุน",
  projectPeriodID: "งวดโครงการ",
  fromProjectPeriodID: "จากงวดโครงการ",
  revenueDescription: "คำอธิบาย",
  revenueAmount: "จำนวนเงิน",
  masBankAccountID: "สมุดบัญชีเงินฝากธนาคารโอนเข้า",
  simulated: "Simulated",
  formatPost: "รูปแบบการ Post",
  debitAmount: "Dr.",
  creditAmount: "Cr.",
  petrolCompanyCode: "รหัสบริษัท",
  petrolCompanyNameTh: "ชื่อบริษัท",
  departmentProject: "หน่วยงานผู้เบิก",
  mainProject: "ชื่อโครงการ",
  arRevenueItemID: "รายได้รอการตรวจสอบ",
  statusLbl: "สถานะ",
  statusTransferLbl: "สถานะการโอนเงิน",
  cash: "เงินสด",
  masPetrolCompanyID: "บริษัทผู้ผลิต/ผู้นำเข้า",
  date: "วันที่",
  dateFromDistillery: "วันที่นำเข้า/นำออกจากโรงกลั่น",
  dateToDistillery: "ถึงวันที่ นำเข้า/นำออกจากโรงกลั่น",
  masPetrolTypeID: "ชนิดน้ำมัน",
  petrolLitterAmount: "ปริมาณน้ำมัน(ลิตร)",
  petrolPerLitterAmount: "อัตรา(บาท/ลิตร)",
  petrolAmount: "จำนวนเงินรวม (ปริมาณน้ำมัน x อัตรา)",
  petrolAmountTotal: "จำนวนเงินรวม",
  addPetrolTransaction: "Add",
  litter: "ลิตร",
  postGlDescription: "คำอธิบายรายการ",
  ButtonPostGL: "Post GL! ผ่านรายการไปบัญชีแยกประเภท",
  btnPrintReport: "Print Report",
  receiveMoneyFromDebter: "ได้รับเงินจาก (ลูกหนี้)",
  debtorForPrintReceipt: "ข้อมูลลูกหนี้ (สำหรับออกใบเสร็จรับเงิน)",
  documentDateFrom: "วันที่เอกสาร ตั้งแต่",
  documentDateTo: "วันที่เอกสาร ถึงวันที่",
  reportCompanyName: "กองทุนเพื่อส่งเสริมการอนุรักษ์พลังงาน",
  masPetrolCompanyCodeName: "รหัส-ชื่อบริษัทผู้ผลิต/ผู้นำเข้า",
  statusOpen: "Open",
  statusClose: "Close",
  referenceBookDateFrom: "วันที่หนังสืออ้างอิง ตั้งแต่",
  referenceBookDateTo: "วันที่หนังสืออ้างอิง ถึงวันที่",
  otherExpenseAmount: "ค่าใช้จ่ายอื่น",
  ifAvailable: " (ถ้ามี)",
  editData: "แก้ไขข้อมูล",
  monthly: "ประจำเดือน",
  yearly: "ปีปฏิทิน",
  refDocumentDateFrom: "วันที่เอกสารอ้างอิง ตั้งแต่",
  refDocumentDateTo: "วันที่เอกสารอ้างอิง ถึงวันที่",
  refDocumentNumber: "เลขที่เอกสารอ้างอิง",
  reportNamePetrolDetail: "รายงานรายได้จากผู้ผลิต - ผู้นำเข้า (ละเอียด)",
  reportNamePetrolSum: "รายงานรายได้จากผู้ผลิต - ผู้นำเข้า (สรุป)",
  employeeNameSurname: "พนักงาน",
  editPassword: "แก้ไข Password",
  newPassword: "รหัสผ่านใหม่",
  currentPassword: "รหัสผ่านปัจจุบัน",
  confirmNewPassword: "ยืนยันรหัสผ่านใหม่",
  oopsErrorPasswordNotMatch:
    "ขออภัย! รหัสผ่านใหม่และยืนยันรหัสผ่านใหม่ ไม่เท่ากัน, รหัสผ่านอย่างน้อย 6 ตัวอักษร",
  oopsErrorPasswordMinimumSixDigit: "ขออภัย! รหัสผ่านอย่างน้อย 6 ตัวอักษร",
  oopsErrorPasswordCurrentNotCorrect: "ขออภัย! รหัสผ่านปัจจุบันไม่ถูกต้อง",
  export: "Export",
  sumMoneyRow: "ยอดเงิน",
  refDocument: "เอกสารอ้างอิง",
  ButtonPrintVoucher: "พิมพ์ใบสำคัญ (Voucher)",
  ButtonCancelPostGl: "ยกเลิก Post GL!",
  buttonPrintWht: "พิมพ์ใบหักภาษี ณ ที่จ่าย (WHT)",
  refDocType: "ประเภทเอกสารอ้างอิง",
  voucherNameTh: "ชื่อใบสำคัญ (Th)",
  voucherNameEn: "ชื่อใบสำคัญ (En) ",
  payableMoneyToCreditor: "ตั้งหนี้ให้กับ (เจ้าหนี้)",
  purchaseOrderNumberRef: "อ้างอิงเลขที่ใบสั่งซื้อ",
  purchaseOrderDateRef: "อ้างอิงวันที่สั่งซื้อ",
  invoiceDateFrom: "วันที่ใบกำกับ ตั้งแต่วันที่",
  invoiceDateTo: "วันที่ใบกำกับ ถึงวันที่",
  deliveryNumber: "เลขที่ใบส่งของ",
  deliveryDate: "วันที่ใบส่งของ",
  dueDate: "ครบกำหนด",
  documentNumberAutoGen: "เลขที่เอกสาร (ระบบ Gen ให้อัตโนมัติ)",
  expensesAmount: "จำนวนเงิน",
  expensesDescription: "คำอธิบาย",
  masExpensesID: "ค่าใช้จ่าย",
  expensesTypeCodeName: "ประเภทค่าใช้จ่าย",
  paymentMoneyToCreditor: "จ่ายชำระให้กับ (เจ้าหนี้)",
  masBankAccountTransferOut: "สมุดบัญชีเงินฝากธนาคารโอนออก",
  bankAccountNumberCreditor: "เลขที่บัญชีธนาคารเจ้าหนี้",
  bankAccountCreditor: "ธนาคารเจ้าหนี้",
  chequeDate: "วันที่บนเช็ค",
  chequeNumber: "เลขที่บนเช็ค",
  masBankAccountCheque: "สมุดบัญชีเงินฝากธนาคารเช็คจ่าย",
  btnGetCustomerBank: "เรียกบัญชีธนาคารจากข้อมูลเจ้าหนี้",
  whtMonthYear: "เดือน/ปีที่จ่าย",
  totalPayAmount: "จำนวนเงิน",
  month: "เดือน",
  year: "ปี",
  customerPayCode: "รหัสผู้จ่าย",
  customerPayName: "ชื่อผู้จ่าย",
  type: "ประเภท",
  whtType: "ประเภท ภ.ง.ด.",
  whtFilingNo: "ครั้งที่",
  whtPayType: "ผู้จ่ายเงิน",
  detailItem: "รายละเอียด",
  whtNameTh: "มาตรา",
  payAmount: "ฐานภาษี",
  totalWhtAmount: "จำนวนภาษี(จ่าย)",
  sentToFundDate: "วันที่นำเงินส่งเข้ากองทุน",
  senderBankBranchTransfer: "สาขาธนาคารผู้โอน",
  taxpayerName: "ชื่อผู้ถูกหัก ณ ที่จ่าย",
  reportNameWhtSum: "รายงานสรุปภาษีเงินได้หัก ณ ที่จ่าย",
  transferDate: "วันที่โอน",
  customerReceiveNameTh: "ชื่อผู้รับเงิน",
  bankAccountTransferFrom: "โอนจากบัญชี",
  bankAccountTransferTo: "เข้าบัญชี",
  bankAccountNumber: "เลขที่บัญชีธนาคาร",
  reportNameApPayment: "รายงานจ่ายชำระ",
  reportNameLedgerAccount: "รายงานบัญชีแยกประเภท เรียงตามรหัสบัญชี",
  reportNameApPaymentNoDebt: "รายงานจ่ายชำระ (ไม่ตั้งหนี้)",
  masBankID: "ธนาคาร",
  statusInProgress: "In Progress",
  statusCompleted: "Completed",
  paymentNumber: "Payment Number",
  paymentNumberDate: "วันที่ได้ Payment Number",
  whtCustomerNameTh: "ชื่อผู้ถูกหักภาษี ณ ที่จ่าย",
  fineLbl: "ค่าปรับ",
  confirmationNumberRef: "เลขที่หนังสือยืนยัน (อ้างอิง)",
  confirmationDateRef: "วันที่หนังสือยืนยัน (อ้างอิง)",
  editDocno: "แก้ไขเลขที่เอกสาร",
  refDocNo: "อ้างอิงเลขที่เอกสาร",
  payChequeDate: "วันที่ออกเช็ค",
  createdChequeDate: "วันที่สร้างเช็ค",
  masBankAccountChequeOut: "สมุดบัญชีเงินฝากธนาคารออกเช็ค",
  chequeStatus: "สถานะเช็ค",
  payeeNameTh: "ชื่อเจ้าหนี้บนเช็ค",
  cashAmount: "จำนวนเงิน",
  chequeDetail: "รายละเอียดเช็ค",
  printChequeLbl: "พิมพ์เช็ค",
  isCrossHolder: 'ขีดคร่อม "หรือผู้ถือ"',
  statusSend: "Send",
  statusPassed: "Passed",
  statusCancel: "Cancel",
  chequeAdjustmentDate: "วันที่ปรับปรุงเช็ค",
  chequeAdjustmentNote: "Note",
  transferAmount: "จำนวนเงินโอน",
  bankFeeAmount: "ค่าธรรมเนียม",
  bankAccountNumberFrom: "จากเลขที่บัญชี",
  bankAccountNumberTo: "เข้าเลขที่บัญชี",
  incomeList: "รายได้",
  expensesList: "ค่าใช้จ่าย",
  creditorType: "ประเภทเจ้าหนี้",
  paidAmount: "จ่ายแล้ว",
  returnCapitalAmount: "ส่งคืน(เงินต้น)",
  returnInterestAmount: "ส่งคืน(ดอกเบี้ย)",
  returnOtherAmount: "ส่งคืน(อื่นๆ)",
  beginningBalanceDate: "วันที่ยกมา",
  isProjectCustomer: "เป็นเจ้าหนี้โครงการ?",
  payByCash: "จ่ายเงินสด",
  guaranteeAmountCut: "หัก - เงินประกันผลงาน",
  socialSecurityAmountCut: "หัก - เงินประกันสังคม",
  carryOverYear: "ปีงบประมาณยกมา",
  approvalPostGlStatusCode: "Post GL",
  approvalPostGlStatus: "สถานะ",
  docNameTh: "ชื่อเอกสาร",
  docPrefixCode: "Prefix Code",
  docFormat: "Format เลขที่เอกสาร",
  docCurrentNumber: "เลขเอกสารล่าสุด",
  ButtonApprove: "อนุมัติ",
  ButtonApproveAllSelected: "อนุมัติรายการ",
  ButtonNotApproveAllSelected: "ไม่อนุมัติรายการ",
  authorLabel: "ระบบบริหารจัดการเงินกองทุน (ส.กทอ.)",
  rptLabelPrintDate: "พิมพ์วันที่",
  rptLabelPageAt: "หน้าที่",
  rptLabelOfPage: "จากทั้งหมด",
  rptLabelPage: "หน้า",
  accountCodeFrom: "รหัสบัญชี ตั้งแต่",
  accountCodeTo: "รหัสบัญชี ถึง",
  accountCodeControl: "จากรหัสบัญชีคุม",
  rptAccountDetail: "รายละเอียดประกอบบัญชี (รวมหมวด)",
  rptLedgerAccountSummary: "บัญชีแยกประเภท - สรุปประจำเดือน",
  rptAccountBeginningBalance: "รายงานยอดยกมา",
  rptBankDeposit: "รายงานฝากเงิน",
  rptBankWithdraw: "รายงานถอนเงิน",
  rptBankTransferBetween: "รายงานโอนเงินระหว่างสมุดเงินฝาก",
  rptBankTransferOut: "รายงานโอนเงินออก",
  rptBankIncome: "รายงานรายได้จากธนาคาร (ดอกเบี้ยรับ)",
  rptBankExpenses: "รายงานค่าใช้จ่ายธนาคาร",
  beginningBalanceAmount: "ยอดยกมา",
  atDate: "ณ วันที่ ยกมา",
  atDateCalendar: "ณ วันที่",
  rptBankBalance: "รายงานยอดคงเหลือเงินฝากธนาคาร แยกตามธนาคาร",
  rptBankStatement: "รายงาน STATEMENT",
  rptProjectSummaryBudget: "รายงานโครงการ (ภาพรวม)",
  rptProjectSummary: "รายงานสถานะการเบิกจ่ายโครงการที่ได้รับการสนับสนุน",
  rptProjectControl: "ทะเบียนคุมการเบิกจ่ายตามหนังสือยืนยันฯ ที่ ",
  FormDocuments: "คู่มือการใช้งาน",
  projectCloseDate: "วันที่ปิดโครงการ",
  projectCancelDate: "วันที่ยกเลิกโครงการ",
  committeeConfirmNumber: "มติคณะกรรมการกองทุนเพื่อฯ",
  subcommitteeConfirmNumber: "คณะอนุกรรมการกองทุนฯ",
  reportNameRptReceiptAndPayment: "รายงานการรับ - จ่ายเงิน",
  reportNameRptExpensesMonthly: "รายงานรายจ่ายประจำเดือน",
  FormRptPetrolSummaryChart: "รายงานแผนภูมิแท่ง รายได้จากผู้ผลิต - ผู้นำเข้า",
  MenuReportGraph: "รายงานกราฟ",
  FormRptPetrolSummaryChartPie:
    "รายงานแผนภูมิวงกลม รายได้จากผู้ผลิต - ผู้นำเข้า",
  FormRptPaymentProjectSummaryChart:
    "รายงานแผนภูมิแท่ง ยอดเบิกจ่ายเงินโครงการ ประจำปีงบประมาณ",
  budgetYearProject: "ปีงบประมาณ (โครงการ)",
  budgetYearFinance: "ปีงบประมาณ (บัญชี-การเงิน)",
  typePetrolPie: "ประเภทข้อมูล",
  typeOfMainProject: "ประเภทโครงการ",
  actionTime: "Action Time",
  method: "Method",
  uri: "URI",
  referer: "Referer",
  browser: "Browser",
  host: "Host",
  proto: "Proto",
  changeMessage: "Change Message",
  actionTimeFrom: "Action Time ตั้งแต่วันที่",
  actionTimeTo: "Action Time ถึงวันที่",
  reportTrialBalance: "งบทดลอง",
  reportWorkSheet: "กระดาษทำการ",
  quarter: "ไตรมาส",
  BoardResolutionTables: "มติฯ",
  boardResolutionID: "มติฯ",
  FormBoardResolutions: "เพิ่ม มติฯ",
  FormBoardResolutionsID: "แก้ไข มติฯ",
  boardResolutionCode: "รหัส มติฯ",
  boardResolutionNameTh: "ชื่อ มติฯ",
  btnExportDocx: "Export MS Word",
  showChartData: "กำหนดการแสดงข้อมูลรหัสบัญชี",
  showAccountCodeData: "กำหนดแสดงรูปแบบรหัสบัญชี",
  rptProjectSummaryWithTransaction: "รายงานสรุปการจ่ายเงินโครงการ (ภาพรวม)",
  rptAllocatedBudgetByCategories: "จัดสรรงบประมาณ (ส่วนสำนักงาน) ปี",
  rptCompareBalanceQuarters: "รายงานเปรียบเทียบยอดคงเหลือ (Balance) รายไตรมาส",
  rptStatementOfFinancialPosition: "งบแสดงฐานะการเงิน",
  formReportSelect: "รูปแบบฟอร์มรายงาน",
  reportNameTh: "ชื่อรูปแบบฟอร์มรายงาน",
  reportType: "ประเภทรายงาน",
  maxColumn: "จำนวน Column",
  lineNumber: "บรรทัดที่",
  columnNumber: "คอลัมน์ที่",
  dataText: "ข้อความตัวหนังสือ",
  colspanNumber: "Merge คอลัมน์",
  dataType: "ประเภทข้อมูล",
  dataSummary: "สูตรคำนวน",
  accountCodeAll: "รหัสบัญชี",
  accountCodeAllForSave:
    'รหัสบัญชีสำหรับหาผลรวม เช่น 1200000-130000,1410000 เป็นต้น ใช้เครื่องหมาย "," แบ่งข้อมูล และ "-" คือ จากรหัสบัญชีถึงรหัสบัญชี',
  dataAlign: "Align",
  dataAlignLbl: "ตำแหน่ง",
  isSumAccountChart: "กำหนดให้หาผลรวมจากรหัสบัญชี",
  isShowUnderline: "ขีดเส้นใต้ หรือไม่?",
  isTextBold: "ตัวหนังสือตัวหนา หรือไม่?",
  lblInfo: "Info",
  lblGlDisapprove: "ไม่อนุมัติ รอแก้ไข",
  approvalNote: "รายละเอียดเหตุผลแนบ",
  ButtonNotApproveAllSelectedShort: "ไม่อนุมัติ",
  FormJournalEntryDetailID: "รายการรายวัน - รายละเอียดรายการรายวัน",
  receiptAddress: "ที่อยู่ออกบิล",
  billingDetail: "รายละเอียดออกบิล",
  lblReceiptHeader: "กระทรวงพลังงาน",
  lblReceiptSubHeader: "สำนักงานบริหารกองทุนเพื่อส่งเสริมการอนุรักษ์พลังงาน",
  lblReceipt: "ใบเสร็จรับเงิน",
  lblGetDataFromDebtor: "เรียกข้อมูล จากข้อมูลลูกหนี้",
  FormRptIncomePendingReviews: "รายงานรายได้รอการตรวจสอบ",
  isCleared: "สถานะการตรวจสอบ",
  statusCleared: "ตรวจสอบแล้ว",
  statusNotCleared: "รอการตรวจสอบ",
  chequeDateFrom: "วันที่บนเช็ค ตั้งแต่",
  chequeDateTo: "วันที่บนเช็ค ถึง",
  lblCustomerCode: "รหัสลูกค้า (ระบบ Gen ให้ อัติโนมัติ)",
  finDocumentDate: "วันที่ใบสำคัญ",
  customerNameReceiveTranferIn: "ชื่อผู้รับโอน",
  customerSearchForTranfer: "ค้นหาข้อมูลโอนเงินให้พนักงาน",
  isBeginningBalance: "เป็นรายการยกมา?",
  lblYes: "ใช่",
  lblNo: "ไม่ใช่",
  paidStatus: "สถานะการจ่ายชำระ",
  confirmationNumberRefProject: "เลขที่หนังสืออ้างอิง",
  confirmationDateRefProject: "วันที่หนังสืออ้างอิง",
  refDocumentDateFromProject: "วันที่หนังสืออ้างอิง ตั้งแต่",
  refDocumentDateToProject: "วันที่หนังสืออ้างอิง ถึงวันที่",
  documentTypeData: "จ่ายเงินจาก (โครงการ/สำนักงาน)",
  oopsPeriodNoAndAmount: "กรุณากรอกข้อมูล (*) ให้ครบถ้วน ก่อนทำการบันทึก!",
  lblEmployeeCode: "รหัสพนักงาน (ระบบ Gen ให้อัตโนมัติ)",
  lblProjectCode: "รหัสโครงการ (ระบบ Gen ให้อัตโนมัติ)",
  bankAccountTransferIn: "รับเข้าบัญชีธนาคาร",
  bankBookLabel: "สมุดบัญชีเงินฝากธนาคาร",
  revenueProjectTypeCodeName: "ประเภทเงินส่งคืนโครงการ",
}
