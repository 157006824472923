<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-6 my-2">
            <div class="card h-100">
              <div class="card-header d-flex">
                <h5 class="mb-0 text-capitalize text-primary">
                  Main (ผูกสูตรปุ๋ย)
                </h5>
                 <div class="my-auto ms-auto">
                  <argon-button
                    color="warning"
                    variant="outline"
                    class="ml-auto"
                    text-align="right"
                     @click="showWaitList"
                    >รอ: {{ StartTotal }}
                  </argon-button>
              </div>
              </div>
              <div class="card-body pt-0">
                <div class="col-12">
                  <div>
                    <h6>
                      {{ $t("ScanTagBarcode") }}
                      <span class="text-danger text-lg">*</span>
                    </h6>
                  </div>
                  <div class="my-2" v-if="isShowAlertBarcode">
                    <argon-alert color="warning" icon="ni ni-bell-55 ni-lg">
                      <strong>ไม่มีข้อมูล!</strong> Barcode: {{ Barcode }}!
                    </argon-alert>
                  </div>
                  <input
                    id="Barcode"
                    type="text"
                    class="form-control form-control-lg h4"
                    maxlength="50"
                    name="Barcode"
                    :disabled="isDisabledBarcode"
                    placeholder="eg. 123456789"
                    @keyup.enter="getTagCode"
                    v-model.trim="Barcode"
                  />
                </div>

                <!-- qr code -->
                <div class="col-12" v-if="isShowFillinQrCode">
                  <div>
                    <h6>
                      {{ $t("ScanQrCode") }}
                      <span class="text-danger text-lg">*</span>
                    </h6>
                  </div>
                  <div class="my-2" v-if="isShowAlertBarcode">
                    <argon-alert color="warning" icon="ni ni-bell-55 ni-lg">
                      <strong>ไม่มีข้อมูล!</strong> QR Code: {{ Barcode }}! ในกลุ่ม Tag Barcode นี้
                    </argon-alert>
                  </div> 
                  <!-- :class="isValidQrCode" -->
                  <div class="form-group">
                      <input
                        id="QrCode"
                        type="text"
                        class="form-control form-control-lg h4"
                        maxlength="200"
                        name="QrCode"
                         :class="isValidQrCode"
                         :disabled="isDisabledQrCode"
                        placeholder="eg. 123456789"
                        ref="qrCodeInput"
                        @keyup.enter="checkQrCode()"
                        v-model.trim="QrCode"
                      />
                  </div>
                </div>
                <!-- product -->
                 <div v-show="isShowProductSelect">
                   <!-- dropdown 1 -->
                  <div class="col-12" >
                    <div>
                      <h6>
                        {{ $t("SelectProduct") }}
                        <span class="text-danger text-lg">*</span>
                      </h6>
                    </div>
                    <select id="ProductCode" name="ProductCode" class="form-control form-control-lg h5"  @change="onChangeProducts($event)">
                      <option value="0">{{ $t("PleaseSelectProduct") }}</option>
                      <option
                        v-for="item in dataProducts.products"
                        :key="item.ID"
                        :value="item.ProductCode"
                      >
                        {{ item.ProductName }}
                      </option>
                    </select>
                    <div
                      class="text-danger text-sm"
                      style="display: block"
                      v-if="isShowAlertProduct"
                    >
                      <strong>โปรดเลือกสินค้า (Product)</strong>
                    </div>
                  </div>
                  <!-- dropdown 2 -->
                  <div class="col-12" >
                    <div>
                      <h6>
                        {{ $t("SelectTable") }}
                        <span class="text-danger text-lg">*</span>
                      </h6>
                    </div>
                    <select id="TableCode" class="form-control form-control-lg h5" @change="onChangeTables($event)">
                      <option value="0">{{ $t("PleaseSelectTable") }}</option>
                      <option
                        v-for="item in dataTables.tables"
                        :key="item.ID"
                        :value="item.TableCode"
                      >
                        {{ item.TableCode }}
                      </option>
                    </select>
                    <div
                      class="text-danger text-sm"
                      style="display: block"
                      v-if="isShowAlertTable"
                    >
                      <strong>โปรดเลือกโต๊ะ (Table)</strong>
                    </div>
                  </div>
                  <!-- dropdown 3 -->
                  <div class="col-12" >
                    <div>
                      <h6>
                        {{ $t("SelectDestination") }}
                        <span class="text-danger text-lg">*</span>
                      </h6>
                    </div>
                    <select id="DestinationCode" class="form-control form-control-lg h5" @change="onChangeDestinations($event)">
                      <option
                        v-for="item in dataDestinations.destinations"
                        :key="item.ID"
                        :value="item.DestinationCode"
                      >
                        {{ item.DestinationCode }}
                      </option>
                    </select>
                    <div
                      class="text-danger text-sm"
                      style="display: block"
                      v-if="isShowAlertDestination"
                    >
                      <strong>โปรดเลือกจุดหมาย (Destination)</strong>
                    </div>
                  </div>
                </div>
                <!-- button -->
                <div class="button-row d-flex mt-4">
                  <argon-button
                    type="button"
                    color="light"
                    variant="gradient"
                    class="js-btn-prev"
                    @click="clearData"
                    >ล้างข้อมูล</argon-button
                  >
                  <argon-button
                    type="button"
                    color="primary"
                    size="md"
                    variant="gradient"
                    class="ms-auto"
                    v-if="isShowStartBtn"
                    @click="checkSaveStart"
                    >บันทึก Start</argon-button
                  >
                  <argon-button
                    type="button"
                    color="primary"
                    size="md"
                    variant="gradient"
                    class="ms-auto"
                    v-if="isShowEditSaveBtn"
                    @click="checkSaveEdit"
                    >บันทึกการแก้ไข</argon-button
                  >
                  <!-- v-show="isShowStart" -->
                  <argon-button
                    type="button"
                    color="success"
                    size="md"
                    variant="gradient"
                    class="ms-auto"
                    v-if="isShowFinishBtn"
                    @click="checkSaveFinish"
                    >บันทึก Finish</argon-button
                  >
                </div>
                <div class="button-row align-items-center mt-2">
                  <argon-button
                    type="button"
                    color="light"
                    size="md"
                    variant="gradient"
                    class="ms-auto"
                    v-if="isShowEditBtn"
                    @click="editData"
                    >แก้ไขข้อมูล</argon-button
                  >
                </div>
                <!-- Wait List  -->
                <div class="mb-0 my-4" v-if="isShowWaitList">
                  <argon-alert
                    icon="ni ni-check-bold ni-md"
                    color="warning"
                    :dismissible="false"
                  >
                    <strong>Info!</strong> รายการรอกด Finish
                    <hr class="horizontal dark m-0 my-2" />
                    <span
                      class="text-sm font-weight-bold my-auto ps-sm-2"
                      v-for="item in dataWaitList.waitList"
                      :key="item.TagCode"
                    >
                      Tag Barcode: {{ item.TagCode }}
                      <br />
                    </span>
                  </argon-alert>
                </div>
                <!-- Matched Success -->
                <div class="mb-0 my-4" v-if="isShowNotification">
                  <argon-alert
                    icon="ni ni-check-bold ni-md"
                    :dismissible="true"
                  >
                    <strong>Success!</strong> บันทึกข้อมูลสำเร็จ
                    <hr class="horizontal dark m-0 my-2" />
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      Tag Barcode: {{ BarcodeResult }}
                      <br />
                      จำนวน QR Code: {{ TotalQrCode }}
                      <br />
                      รหัสสินค้า: {{ ProductCode }}
                      <br />
                      ชื่อสินค้า: {{ ProductName }}
                      <br />
                      ชนิดถุง: {{ BagType }}
                      <br />
                      โต๊ะ: {{ TableCode }}
                      <br />
                      Destination: {{ DestinationCode }}
                      <br />
                      สถานะ Tag Barcode: {{ $t(TagStatus) }}
                      <br />
                      วันที่ทำรายการ: {{ UpdatedAt }}
                    </p>
                  </argon-alert>
                </div>
                <!-- QR Codde Detail -->
                <div v-if="isShowCodeDetail">
                  <div
                    class="d-flex align-items-center justify-content-center mb-0 bg-gray-100 border-radius-lg p-2 my-4"
                  >
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      จำนวน QR Code:
                    </p>
                    <p
                      class="ms-auto text-primary h3 font-weight-bold my-auto me-3"
                    >
                      {{ TotalQrCode }}
                    </p>
                  </div>
                  <div class="mb-0 bg-gray-100 border-radius-lg p-2 my-4">
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <p class="text-sm font-weight-bold my-auto ps-sm-2">
                        สถานะ Tag Barcode:
                      </p>
                      <p
                        class="ms-auto text-primary h6 font-weight-bold my-auto me-3"
                      >
                        {{ $t(TagStatus) }}  
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <p class="text-sm font-weight-bold my-auto ps-sm-2">
                        ชนิดถุง:
                      </p>
                      <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                        {{ BagType }}
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Product Detail -->
                <div
                  v-if="isShowProductDetail"
                  class="mb-0 bg-gray-100 border-radius-lg p-2 my-4"
                >
                  <div class="d-sm-flex align-items-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      รายละเอียดสินค้า (Product):
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      รหัส:
                    </p>
                    <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                       {{ TagStatus == 'Mprostart' || TagStatus== 'Ready' ? SelectedProductCode : ProductCode }}
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      ชื่อ:
                    </p>
                    <p
                      class="ms-auto text-primary h6 font-weight-bold my-auto me-3"
                    >
                      {{ ProductName }}
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      โต๊ะ:
                    </p>
                    <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                      {{ TagStatus == 'Mprostart' || TagStatus== 'Ready' ? SelectedTableCode : TableCode }}
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      Destination:
                    </p>
                    <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                      {{ TagStatus == 'Mprostart' || TagStatus== 'Ready' ? (SelectedDestinationCode==0?'': SelectedDestinationCode) : DestinationCode }}

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- section how to use -->
          <div class="col-lg-6 my-2">
            <div class="card">
              <div
                class="p-3 mx-4 text-center card-header d-flex justify-content-center"
              >
                <div
                  class="icon icon-shape icon-md shadow text-center border-radius-lg bg-gradient-success"
                >
                  <i
                    class="opacity-10 ni ni-single-copy-04"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="p-3 pt-0 mx-2 card-body">
                <h6 class="mb-0 text-center">วิธีการใช้งาน</h6>
                <hr class="my-3 horizontal dark" />
                <div class="">
                  <ul class="list-unstyled">
                    <li>1. สแกน TAG Barcode ที่ต้องการนำมาประกอบกับถุงนอก</li>
                    <ul>
                      <li>
                        (ระบบจะแสดงข้อมูล Barcode และ จำนวน QR Code
                        ที่กรอบด้านล่าง)
                      </li>
                    </ul>
                    <li>
                      2. หลังจากนั้น แสกน QR Code ในกอง เพื่อตรวจสอบว่า QR Code เป็นของ Tag Barcode นี้หรือไม่
                    </li>
                    <ul>
                      <li>
                        ถ้าไม่ตรง ระบบจะแจ้งเตือนว่าไม่มี QR Code นี้ในกลุ่ม Tag Barcode นี้ ให้กรอกใหม่จนกว่าจะตรง
                      </li>
                      <li>
                        ถ้าตรง ระบบจะแสดงข้อมูลสินค้า ที่กรอบด้านล่าง ให้ดำเนินการต่อไป
                      </li>
                    </ul>
                    <li>
                      3. หลังจากนั้น เลือกชนิดสินค้า, เลือกโต๊ะ และเลือกจุดหมาย (Destination)
                    </li>
                    <ul>
                      <li>
                        ชื่อสินค้าจะสามารถเลือกได้จาก Drop down list
                        ซึ่งได้มีการตั้งค่าไว้ก่อนแล้ว โดยจะแสดงเฉพาะสินค้าที่เปิดให้ใช้งาน และมีถุงชนิดเดียวกันกับ Tag Barcode
                      </li>
                      <li>
                        โต๊ะจะสามารถเลือกได้จาก Drop down list
                        ซึ่งได้มีการตั้งค่าไว้ก่อนแล้ว เช่นกัน
                      </li>
                      <li>
                        จุดหมาย Destination จะสามารถเลือกได้จาก Drop down list
                        โดยจะแสดงเฉพาะจุดหมายของสินค้าที่เลือกไว้เท่านั้น
                      </li>
                      <li>ระบบจะแสดงข้อมูลสินค้า ที่กรอบด้านล่าง</li>
                    </ul>
                    <li>
                      4. กดปุ่ม "บันทึก Start" เพื่อบันทึกข้อมูล
                      เริ่มต้นการประกอบถุงนอก
                    </li>
                    <li>5. กดปุ่ม "ล้างข้อมูล" เพื่อล้างข้อมูลที่กรอกไว้</li>
                    <li>
                      6. กดปุ่ม "แก้ไขข้อมูล" เพื่อแก้ไขข้อมูลสินค้าที่เลือกไว้
                    </li>
                    <li>
                      7. กดปุ่ม "บันทึก Finish" เพื่อบันทึกข้อมูล
                      สิ้นสุดการประกอบถุงนอก
                    </li>
                    <li>
                      8. "รอ: xx" : หมายถึงจำนวนรายการ Tag Barcode
                      ที่ยังประกอบถุงไม่เสร็จ (ยังไม่ได้กด Finish)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios"
import {
  ref,
  onServerPrefetch,
  onMounted,
  reactive,
  nextTick,
  toRaw,
  inject,
} from "vue"
import { useForm, useField } from "vee-validate"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import { object, string, setLocale, lazy } from "yup"
import Swal from "sweetalert2"
import getDateFormat from "@/includes/getDateFormat"
import ArgonLabel from "@/components/ArgonLabel.vue"

import ArgonButton from "@/components/ArgonButton.vue"
import ArgonAlert from "@/components/ArgonAlert.vue"
import ArgonBadge from "@/components/ArgonBadge.vue"
import ArgonSnackbar from "@/components/ArgonSnackbar.vue"


export default {
  name: "DashboardMain",
  components: {
    ArgonButton,
    ArgonAlert,
    ArgonLabel,
    ArgonBadge,
    ArgonSnackbar,
  },
  data() {
    return {}
  },
  setup(props, ctx) {
    const { t } = useI18n({})
    const router = useRouter()

    var dataProducts = reactive({
      products: [],
    })
    var dataWaitList = reactive({
      waitList: [],
    })
    var dataTables = reactive({
      tables: [],
    })
    let dataTableAll = reactive({
      tables: [],
    })
    var dataDestinations = reactive({
      destinations: [],
    })

    var QrCode = ref('')

    var ProductCode = ref(null)
    var SelectedProductCode = ref(null)
    var ProductName = ref(null)
    var TagProductStartAt = ref(null)
    var TagProductFinishAt = ref(null)
    var TagProductStartBy = ref(null)
    var TagProductFinishBy = ref(null)
    var TagStatus = ref(null)
    var TotalQrCode = ref(null)
    var BagType = ref(null)
    var UpdatedAt = ref(null)
    var TableCode = ref(null)
    var DestinationCode = ref(null)
    

    //show
    var isShowFinishBtn = ref(false)
    var isShowCodeDetail = ref(false)
    var isShowProductDetail = ref(false)
    var isShowStartBtn = ref(false)
    var isShowEditBtn = ref(false)
    var isShowNotification = ref(false)
    var isShowAlertBarcode = ref(false)
    var isShowAlertProduct = ref(false)
    var isShowProductSelect = ref(false)
    var isShowEditSaveBtn = ref(false)
    var isDisabledBarcode = ref(false)
    var Barcode = ref(null)
    var BarcodeResult = ref(null)
    var StartTotal = ref(null)
    var tagCodeError = ref(null)
    var isShowWaitList = ref(false)

    //added  getQrCode
    var isShowFillinQrCode = ref(false)
    var isDisabledQrCode = ref(false)
    var isShowAlertQrCode = ref(false)
    var isValidQrCode = ref('')
    var isShowAlertTable = ref(false)
    var isShowAlertDestination = ref(false) 
    var SelectedTableCode = ref(null)
    var SelectedDestinationCode = ref(null) 
  

    const { handleSubmit } = useForm({
      validationSchema: schema,
    })

    function focusOnBarCode() {
      const el = document.getElementById("Barcode")
      el.focus()
    }

    function unFocusOnBarCode() {
      const el = document.getElementById("Barcode")
      el.blur()
    }

    function focusOnQrCode() {
      nextTick(() => {
        const qrCodeElement = document.getElementById("QrCode")
        if (qrCodeElement) {
          qrCodeElement.focus();
      } else {
          console.warn("qrCodeElement is not available for focusing");
        }
      });
    }

    function unFocusOnQrCode() {
      const el = document.getElementById("QrCode")
      el.blur()
    }

    function unAuthorized() {
      localStorage.removeItem("curAuthen")
      localStorage.removeItem("perms")
      router.push("/login")
    }

    function getProductByBagType(bagType) {
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      let res = axios({
        method: "get",
        url: "productByBagType-active/"+bagType,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          let rs = response.data.data
          dataProducts.products = rs
          return rs
        })
        .catch((error) => {
          // Swal.fire("ไม่มีสินค้าที่เปิดให้ใช้งานในระบบ")
          return false // pass to finish
        })
        .then((rs) => {
          // // set product code with local storage
          if(JSON.parse(localStorage.getItem("pro"))){
            if(JSON.parse(localStorage.getItem("pro")).code){
              const recentProduct = JSON.parse(localStorage.getItem("pro")).code

              if(recentProduct != null){
                const dropdown = document.getElementById('ProductCode');
                for (const option of dropdown.options) {
                  if (option.value === recentProduct) { 
                    option.selected = true;
                    SelectedProductCode.value = option.value
                    ProductCode.value = option.value  
                    getDestinationByProductCode(recentProduct)
                    showProductDetail(recentProduct)
                    break;
                  }
                }
              }

            }
          }else{
            //pro is null
            SelectedProductCode.value =""
            ProductCode.value = ""
          }
          return rs // for await purpose
        })
      return res
    }

    async function getDestinationByProductCode(productCode) {
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      let res = await axios({
        method: "get",
        url: "productDestinationsByProductCode-Active/"+productCode,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          let rs = response.data.data
          dataDestinations.destinations = rs

          SelectedDestinationCode.value = rs[0].DestinationCode
          isShowAlertDestination.value = false
          return rs
        })
        .catch((error) => {
          return false // pass to finish
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
    }

    function getTable() {
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      let res = axios({
        method: "get",
        url: "tables-active",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          let rs = response.data.data
          // dataTables.tables = rs
          dataTableAll.tables = rs
          return rs
        })
        .catch((error) => {
          // Swal.fire("ไม่มีสินค้าที่เปิดให้ใช้งานในระบบ")
          return false // pass to finish
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
    }
    
    onMounted(() => {
      focusOnBarCode()
      getStartStatusTotal()
      getTable()
    })

    function onChangeProducts(event) {
      SelectedProductCode.value = event.target.value;
      showProductDetail(SelectedProductCode.value)

      SelectedDestinationCode.value = 0
      getDestinationByProductCode(SelectedProductCode.value)
      isShowAlertProduct.value = false
    }

    function onChangeTables(event) {
      SelectedTableCode.value = event.target.value;
      isShowAlertTable.value = false
    }

    function onChangeDestinations(event) {
      SelectedDestinationCode.value = event.target.value;
      isShowAlertDestination.value = false
    }

    function showProductDetail(id) {
      let obj = dataProducts
      dataProducts.products.forEach((row) => {
        if (row.ProductCode == id) {
          ProductName.value = row.ProductName
          ProductCode.value = row.ProductCode
        }
      })
      isShowProductDetail.value = true
    }

    const schema = lazy(() =>
      object({
        Barcode: string()
          .required(() => t("errors.required", { field: t("Barcode") }))
          .min(18, t("errors.min", { length: 18 }))
          .max(50, t("errors.max", { length: 50 })),
      })
    )

    function checkSaveStart() {
      if (Barcode.value == null || Barcode.value == "" || Barcode.value == 0) {
        showAlertNoBarcode()
        return
      }
      if (SelectedProductCode.value == null || SelectedDestinationCode.value =="" || SelectedProductCode.value == 0) {
        isShowAlertProduct.value = true
        return
      }
      if (SelectedTableCode.value == null || SelectedTableCode.value =="" || SelectedTableCode.value == 0) {
        isShowAlertTable.value = true
        return
      }
      if (SelectedDestinationCode.value == null || SelectedDestinationCode.value =="" || SelectedDestinationCode.value == 0) {
        isShowAlertDestination.value = true
        return
      }
     
      saveStart()
    }

    function checkSaveFinish() {
      if (Barcode.value == null || Barcode.value == "" || Barcode.value == 0) {
        showAlertNoBarcode()
        return
      }
      showAlertConfirm()
    }

    function checkSaveEdit() {
      isDisabledBarcode.value = true
      if (Barcode.value == null || Barcode.value == "" || Barcode.value == 0) {
        showAlertNoBarcode()
        return
      }
      if (SelectedProductCode.value == null || SelectedDestinationCode.value =="" || SelectedProductCode.value == 0) {
        isShowAlertProduct.value = true
        return
      }
      if (SelectedTableCode.value == null || SelectedTableCode.value =="" || SelectedTableCode.value == 0) {
        isShowAlertTable.value = true
        return
      }
      if (SelectedDestinationCode.value == null || SelectedDestinationCode.value =="" || SelectedDestinationCode.value == 0) {
        isShowAlertDestination.value = true
        return
      }
      saveEdit()
    }

    const saveStart = handleSubmit((values) => {
      const token = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      const objStart = {
        TagCode: Barcode.value,
        ProductCode: SelectedProductCode.value,
        TableCode: SelectedTableCode.value,
        DestinationCode: SelectedDestinationCode.value,
        TagStatus: "Mprostart",
        TagProductStartBy: uid,
        UpdatedBy: uid,
      }
      let res = axios({
        method: "put",
        url: "tagProductStart/" + Barcode.value,
        data: objStart,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          clearData()
          let rs = response.data.data
          BarcodeResult.value = rs.TagCode
          ProductName.value = rs.ProductName
          ProductCode.value = rs.ProductCode
          TagStatus.value = rs.TagStatus
          TotalQrCode.value = rs.TotalQrCode
          BagType.value = rs.BagType
          TableCode.value = rs.TableCode
          DestinationCode.value = rs.DestinationCode
          UpdatedAt.value = ""
          if (rs.UpdatedAt != null) {
            UpdatedAt.value = getDateFormat(rs.UpdatedAt)
          }

          //check again
          localStorage.setItem(
            "pro",
            rs.ProductCode != null
              ? JSON.stringify({ code: rs.ProductCode })
              : null
          )

          isShowNotification.value = true
          isShowFinishBtn.value = false
          isShowEditBtn.value = false
          isShowProductSelect.value = false
          isDisabledBarcode.value = false
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          showAlertCannotSave()
          return false // return false to stop the form submission
        })
        .then((rs) => {
          getStartStatusTotal()
          return rs // for await purpose
        })
      return res
      //save
    }, onInvalidSubmit)

    const saveFinish = handleSubmit((values) => {
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      const objFinish = {
        TagCode: Barcode.value,
        TagStatus: "Mprofinish",
        TagProductFinishBy: uid,
        UpdatedBy: uid,
      }
      let res = axios({
        method: "put",
        url: "tagProductFinish/" + Barcode.value,
        data: objFinish,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          clearData()
          let rs = response.data.data
          BarcodeResult.value = rs.TagCode
          ProductName.value = rs.ProductName
          ProductCode.value = rs.ProductCode
          TagStatus.value = rs.TagStatus
          TotalQrCode.value = rs.TotalQrCode
          BagType.value = rs.BagType
          TableCode.value = rs.TableCode
          DestinationCode.value = rs.DestinationCode
          UpdatedAt.value = ""
          if (rs.UpdatedAt != null) {
            UpdatedAt.value = getDateFormat(rs.UpdatedAt)
          }
          isShowNotification.value = true
          isShowFinishBtn.value = false
          isShowEditBtn.value = false
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          showAlertCannotSave()
          return false // return false to stop the form submission
        })
        .then((rs) => {
          getStartStatusTotal()
          return rs // for await purpose
        })
      return res
      //save
    }, onInvalidSubmit)

    const saveEdit = handleSubmit((values) => {
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      const objEdit = {
        TagCode: Barcode.value,
        ProductCode: SelectedProductCode.value,
        TableCode: SelectedTableCode.value,
        DestinationCode: SelectedDestinationCode.value,
        UpdatedBy: uid,
      }
      let res = axios({
        method: "put",
        url: "tagProductEdit/" + Barcode.value,
        data: objEdit,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          clear()
          let rs = response.data.data
          BarcodeResult.value = rs.TagCode
          Barcode.value = rs.TagCode
          ProductName.value = rs.ProductName
          ProductCode.value = rs.ProductCode
          TagStatus.value = rs.TagStatus
          TotalQrCode.value = rs.TotalQrCode
          BagType.value = rs.BagType
          TableCode.value = rs.TableCode
          DestinationCode.value = rs.DestinationCode
          UpdatedAt.value = ""
          if (rs.UpdatedAt != null) {
            UpdatedAt.value = getDateFormat(rs.UpdatedAt)
          }
          isShowNotification.value = true
          isShowFinishBtn.value = true
          isShowEditBtn.value = true
          isShowProductSelect.value = false
          isShowEditSaveBtn.value = false
          unFocusOnBarCode()
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          showAlertCannotSave()
          return false // return false to stop the form submission
        })
        .then((rs) => {
          getStartStatusTotal()
          return rs // for await purpose
        })
      return res
      //save
    }, onInvalidSubmit)

   
    //get data
    async function getTagCode() {
      if (Barcode.value) {
        const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
        let res = await axios({
          method: "get",
          url: "tagTransactionByTagCode/" + Barcode.value,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
          .then((response) => {
            clearDataAfterBarcode()
            let rs = response.data.data
            ProductCode.value = rs.ProductCode
            SelectedProductCode.value = rs.ProductCode
            SelectedTableCode.value = rs.TableCode  
            SelectedDestinationCode.value = rs.DestinationCode  
            ProductName.value = rs.ProductName
            TagProductStartAt.value = rs.TagProductStartAt
            TagProductFinishAt.value = rs.TagProductFinishAt
            TagProductStartBy.value = rs.TagProductStartBy
            TagProductFinishBy.value = rs.TagProductFinishBy
            TagStatus.value = rs.TagStatus
            TotalQrCode.value = rs.TotalQrCode
            BagType.value = rs.BagType
            TableCode.value = rs.TableCode
            DestinationCode.value = rs.DestinationCode

            isShowFillinQrCode.value = true // always show
            focusOnQrCode()
            return rs
          })
          .catch((error) => {
            showAlertTagCodeErrorNotFound()
            return false // pass to finish
          })
        return res
      } else {
        showAlertNoBarcode()
        isDisabledBarcode.value = false
      }
    }

    function showAlertNoQrCode() {
      Swal.fire({
        title: "Not Found!",
        text: "ไม่มีข้อมูล QR Code " + QrCode.value + "ในระบบ",
        willClose: () => {
          document.activeElement?.blur();
        },
      })
    }

    function formatQrCode(qrcode) {
      var code = ""
      if (qrcode != null && qrcode != "") {
        var qrSplit = qrcode.split("=")
        //take last string
        code = qrSplit[qrSplit.length - 1]
      } else {
        showAlertNoQrCode()
      }
      return code
    }

    function checkQrCode() {
      var qrcode = formatQrCode(QrCode.value)
      QrCode.value = qrcode
      
      getQrCode()
      return true
    }

    //getQrCode
    async function getQrCode() {
      if (QrCode.value && Barcode.value) {
        const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
        let res = await axios({
          method: "get",
          url: "qrCodeWithTagCode/" + Barcode.value+"/"+QrCode.value, 
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
          .then((response) => {
            let count = response.data.count
            if (count == 0) {
              isValidQrCode.value = 'is-invalid'
              showAlertQrCodeErrorNotFoundInTag()
              return false // pass to finish
            }else{
              isValidQrCode.value = 'is-valid'
              isDisabledQrCode.value = true 
              isDisabledBarcode.value = true
              // if status = Ready then show product select 
              // else status = Mprostart then show product detail

              //get product by bag type
              if(TagStatus.value == "Ready"){
                getProductByBagType(BagType.value)
              }else{
                showProductDetail(ProductCode.value)
              }
              

              //assign table
              dataTables.tables = dataTableAll.tables

              //show Product Detail
              isShowNotification.value = false
              isDisabledBarcode.value = true
              isShowEditSaveBtn.value = false
              switch (TagStatus.value) {
                case "Ready":
                  isShowStartBtn.value = true
                  isShowFinishBtn.value = false
                  isShowEditBtn.value = false
                  //show
                  isShowCodeDetail.value = true
                  isShowProductDetail.value = false
                  isShowAlertBarcode.value = false
                  isShowAlertProduct.value = false
                  isShowProductSelect.value = true
                  break
                case "Mprostart":
                  isShowStartBtn.value = false
                  isShowFinishBtn.value = true
                  isShowEditBtn.value = true
                  isShowEditSaveBtn.value = false
                  //show
                  isShowCodeDetail.value = true
                  isShowProductDetail.value = true
                  isShowAlertBarcode.value = false
                  isShowAlertProduct.value = false
                  isShowProductSelect.value = false
                  break
                default:
                  isShowStartBtn.value = false
                  isShowFinishBtn.value = false
                  isShowEditBtn.value = false
                  isShowCodeDetail.value = true
                  isShowProductDetail.value = true
                  isShowAlertBarcode.value = false
                  isShowAlertProduct.value = false
                  isShowProductSelect.value = false
                  break
              }
            }
            return count
          })
          .catch((error) => {
            if (error.response.status === 401) {
              unAuthorized()
              return
            }
            showAlertQrCodeErrorNotFoundInTag()
            return false // pass to finish
          })
        return res
      } else {
        showAlertQrCodeฺTagCodeEmpty()
        isDisabledQrCode.value = false
      }
    }

    async function getStartStatusTotal() {
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      const status = "Mprostart"
      let res = await axios({
        method: "get",
        url: "totalRowsByTagStatus/" + status,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          StartTotal.value = response.data.totalRows
          return response.data
        })
        .catch((error) => {
          return false // pass to finish
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
    }

    function showWaitList() {
      if (isShowWaitList.value == false) {
        getWaitList()
      } else {
        focusOnBarCode()
      }
      isShowWaitList.value = !isShowWaitList.value
    }

    async function getWaitList() {
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      const status = "Mprostart"
      let res = await axios({
        method: "get",
        url: "tagsByTagStatus/" + status,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          var rs = response.data.data
          dataWaitList.waitList = rs
          return rs
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          return false // pass to finish
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
    }

    let isInvalidSubmit = false
    function onInvalidSubmit({ values, errors, results }) {
      isInvalidSubmit = true
      console.log(errors) // a map of field names and their first error message
    }

    function clearData() {
      clear()
      setTimeout(function () {
        focusOnBarCode()
      }, 1000)
    }

    function clear() {
      Barcode.value = ""
      isShowStartBtn.value = false
      isShowFinishBtn.value = false
      isShowEditBtn.value = false
      isShowCodeDetail.value = false
      isShowProductDetail.value = false
      isShowAlertProduct.value = false
      isShowAlertBarcode.value = false
      isShowNotification.value = false
      isShowProductSelect.value = false
      isDisabledBarcode.value = false
      isShowEditSaveBtn.value = false
      isShowWaitList.value = false
      isShowFillinQrCode.value = false
      isDisabledQrCode.value = false
      QrCode.value = ''
      isValidQrCode.value = ''
      //added
      ProductCode.value = null
      ProductName.value = null
      TableCode.value = null
      DestinationCode.value = null
      SelectedProductCode.value = null
      SelectedTableCode.value = null
      SelectedDestinationCode.value = null

      dataProducts.products = []
      dataDestinations.destinations = []
      dataTables.tables = []

      // focusOnBarCode()
    }

    function clearDataAfterBarcode(){
      isShowStartBtn.value = false
      isShowFinishBtn.value = false
      isShowEditBtn.value = false
      isShowCodeDetail.value = false
      isShowProductDetail.value = false
      isShowAlertProduct.value = false
      isShowAlertBarcode.value = false
      isShowNotification.value = false
      isShowProductSelect.value = false
      isDisabledBarcode.value = false
      isShowEditSaveBtn.value = false
      isShowWaitList.value = false
      isShowFillinQrCode.value = false
      isDisabledQrCode.value = false
      QrCode.value = ''
      isValidQrCode.value = ''
    }

     //show alert
     function showAlertTagCodeErrorNotFound() {
      Swal.fire({
        title: "Not Found!",
        text: "ไม่มีรหัส Barcode " + Barcode.value + " ในระบบ",
        willClose: () => {
          document.activeElement?.blur();
          clear()
          isDisabledBarcode.value = false
          setTimeout(() => {
            focusOnBarCode()
          }, 1000);
        },
      })
    }

    function showAlertQrCodeฺTagCodeEmpty() {
      Swal.fire({
        title: "Warning!",
        text: "ไม่มีรหัส Barcode หรือ QR Code กรุณาแสกน QR Code ก่อน",
        willClose: () => {
          document.activeElement?.blur();
          isDisabledQrCode.value = false
          QrCode.value = ''
          isShowFillinQrCode.value = true
          setTimeout(() => {
            focusOnQrCode()
          }, 1000);
        },
      })
    }

    // //not found qr code in tag
    function showAlertQrCodeErrorNotFoundInTag() {
      Swal.fire({
        title: "Not Found!",
        text: "ไม่มี QR Code " + QrCode.value + " ในกลุ่ม Tag นี้",
        willClose: () => {
          document.activeElement?.blur();
          isDisabledQrCode.value = false
          QrCode.value = ''
          isShowFillinQrCode.value = true
          setTimeout(() => {
            focusOnQrCode()
          }, 1000);
        },
      })
    }


   

    function showAlertConfirm() {
      Swal.fire({
        title: "ยืนยันบันทึกข้อมูล?",
        text: "จำนวน QR Code มี " + TotalQrCode.value + " ดวง!",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน, บันทึกข้อมูล!",
        cancelButtonText: "ยกเลิก",
        willClose: () => {
          document.activeElement?.blur();
          // isDisabledQrCode.value = false
          // QrCode.value = ''
          // isShowFillinQrCode.value = true
          // setTimeout(() => {
          //   focusOnQrCode()
          // }, 1000);
        },
      }).then((result) => {
        if (result.isConfirmed) {
          saveFinish()
        }
      })
    }

    function showAlertCannotSave() {
      Swal.fire(
        "Error!",
        "ไม่สามารถบันทึกข้อมูลได้ กรุณาตรวจสอบ Barcode " +
          Barcode.value +
          " ในระบบ ว่ามีอยู่จริง!",
        ""
      )
    }

     //end show alert


    async function editData() {
      SelectedDestinationCode.value = DestinationCode.value
      await getDestinationByProductCode(ProductCode.value)
      isShowEditSaveBtn.value = true
      isShowEditBtn.value = false
      isShowStartBtn.value = false
      isShowFinishBtn.value = false
      isShowProductSelect.value = true
      isShowAlertProduct.value = false
      isShowAlertBarcode.value = false
      isShowAlertTable.value = false
      isShowAlertDestination.value = false
      isShowNotification.value = false

      //set checked DestinationCode
      const elProductCode = document.getElementById("ProductCode")
      const elTableCode = document.getElementById("TableCode")
      const elDestinationCode = document.getElementById("DestinationCode")
     
      elProductCode.value = ProductCode.value
      elTableCode.value = TableCode.value
      elDestinationCode.value = DestinationCode.value
    }

    return {
      t,
      dataProducts,
      ProductName,
      ProductCode,
      TagProductStartAt,
      TagProductFinishAt,
      TagProductStartBy,
      TagProductFinishBy,
      TagStatus,
      TotalQrCode,
      onChangeProducts,
      getTagCode,
      saveStart,
      saveFinish,
      clear,
      checkSaveStart,
      checkSaveFinish,
      showAlertConfirm,
      getStartStatusTotal,
      editData,
      checkSaveEdit,
      isInvalidSubmit,
      isShowFinishBtn,
      isShowEditBtn,
      isShowStartBtn,
      isShowCodeDetail,
      isShowProductDetail,
      isShowNotification,
      isShowAlertBarcode,
      isShowAlertProduct,
      isShowProductSelect,
      isShowEditSaveBtn,
      isDisabledBarcode,
      tagCodeError,
      Barcode,
      BarcodeResult,
      StartTotal,
      BagType,
      UpdatedAt,
      clearData,
      showWaitList,
      isShowWaitList,
      dataWaitList,
      isShowFillinQrCode,
      isDisabledQrCode,
      getQrCode,
      isShowAlertQrCode,
      QrCode,
      isValidQrCode,
      SelectedProductCode,
      dataTables,
      dataDestinations,
      isShowAlertTable,
      SelectedTableCode,
      SelectedDestinationCode,
      isShowAlertDestination,
      onChangeTables,
      onChangeDestinations,
      TableCode,
      DestinationCode,
      checkQrCode,
    }
  },
  methods: {
    refreshToken() {
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      axios({
        method: "get",
        url: "refresh",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          this.response = response.data.data
          var r = this.response
          localStorage.setItem(
            "curAuthen",
            JSON.stringify({
              name: r.username,
              fullname: r.fullname,
              token: r.token,
              isAuthenticated: true,
              typecode: r.typecode,
              uid: r.uid,
              tid: r.tid,
              groups: r.groups,
            })
          )
          localStorage.setItem("perms", JSON.stringify(response.data.perms))

          // console.log("refresh token success")
          const curAuthen = JSON.parse(localStorage.getItem("curAuthen"))
          const perms = JSON.parse(localStorage.getItem("perms"))
        })
        .catch((error) => {
          if (
            error.response.status == 401 ||
            error.response.status == 403 ||
            error.response.status == 500
          ) {
            localStorage.removeItem("curAuthen")
            localStorage.removeItem("perms")
            this.$router.push("/login")
          } else if (error.response.status == 400) {
            // console.log("refresh token fail")
          }
        })
    },
  },
  mounted() {
    this.refreshToken()
  },
}
</script>
