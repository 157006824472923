import { createRouter, createWebHistory } from "vue-router"
import SplashScreen from "../views/auth/SplashScreen.vue"
import SigninUser from "../views/auth/SigninUser.vue"
import Dashboard from "../views/dashboards/Dashboard.vue"
import UserTable from "../views/systems/UserTable.vue"
import UserTables from "../views/systems/UserTables.vue"
import UserForms from "../views/systems/UserForms.vue"
import ProductTables from "../views/systems/ProductTables.vue"
import DeleteTag from "../views/systems/DeleteTag.vue"
import ReTagQrCodes from "../views/systems/ReTagQrCodes.vue"
import ChangePassword from "../views/systems/ChangePassword.vue"
import ResetPassword from "../views/systems/ResetPassword.vue"
import FillProduct from "../views/systems/FillProduct.vue"
import ActivateProduct from "../views/systems/ActivateProduct.vue"
import ReTagQrCodesStationThree from "../views/systems/ReTagQrCodesStationThree.vue"
import ChangeTagStatus from "../views/systems/ChangeTagStatus.vue"
import Error404 from "../views/auth/error/Error404.vue"
// import Error500 from "../views/auth/error/Error500.vue"
import DestinationTables from "../views/systems/DestinationTables.vue"


const routes = [
  {
    path: "/welcomeMobile/:id+",
    name: "SplashScreenMobile",
    component: SplashScreen,
  },
  {
    path: "/welcome/:id+",
    name: "SplashScreen",
    component: SplashScreen,
  },
  {
    path: "/login",
    name: "SigninUser",
    component: SigninUser,
  },
  {
    path: "/",
    name: "/",
    redirect: "/login",
  },
  {
    path: "/main",
    name: "Main",
    component: Dashboard,
  },
  {
    path: "/deleteTag",
    name: "DeleteTag",
    component: DeleteTag,
  },
  {
    path: "/retagQrcode",
    name: "RetagQrcode",
    component: ReTagQrCodes,
  },
  {
    path: "/productSetting",
    name: "ProductSetting",
    component: ProductTables,
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
  },

  //station 3
  {
    path: "/fillProduct",
    name: "FillProduct",
    component: FillProduct,
  },
  {
    path: "/activateProduct",
    name: "ActivateProduct",
    component: ActivateProduct,
  },
  {
    path: "/reTagQrCodesStationThree",
    name: "ReTagQrCodesStationThree",
    component: ReTagQrCodesStationThree,
  },
  {
    path: "/changeTagStatus",
    name: "ChangeTagStatus",
    component: ChangeTagStatus,
  },

  //end here
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },

  //UserTables
  {
    path: "/systems/settings/users",
    name: "UserTable",
    component: UserTable,
  },
  {
    path: "/systems/settings/userTables",
    name: "UserTables",
    component: UserTables,
  },
  {
    path: "/systems/settings/userForms",
    name: "UserForms",
    component: UserForms,
  },
  {
    path: "/productSetting",
    name: "ProductSetting",
    component: ProductTables,
  },
  {
    path: "/destinations",
    name: "Destinations",
    component: DestinationTables,
  },
  {
    path: "/error404",
    name: "Error404",
    component: Error404,
  },
  // {
  //   path: "/authentication/error/error500",
  //   name: "Error Error500",
  //   component: Error500,
  // },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
})

export default router
