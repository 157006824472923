<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-6 my-2">
            <div class="card h-100">
              <div class="card-header d-flex">
                <h5 class="mb-0 text-capitalize text-primary">
                  Retag QR Codes (ย้ายมัดถุง)
                </h5>
              </div>
              <div class="card-body pt-0">
                <div class="col-12">
                  <div>
                    <h6>
                      {{ $t("ScanTagBarcode") }}
                      <span class="text-danger text-lg">*</span>
                    </h6>
                  </div>
                  <input
                    id="Barcode"
                    type="text"
                    class="form-control form-control-lg h4"
                    name="Barcode"
                    maxlength="50"
                    :disabled="isDisabledBarcode"
                    placeholder="eg. 123456789"
                    @keyup.enter="getTagCode"
                    v-model.trim="Barcode"
                  />
                </div>

                <div class="col-12" v-show="isShowQrCodeSelect">
                  <div>
                    <h6>
                      แสกน QR Code
                      <span class="text-danger text-lg">*</span>
                    </h6>
                  </div>

                  <div class="">
                    <input
                      type="text"
                      id="QrCode"
                      maxlength="200"
                      @keyup.enter="checkQrCode()"
                      v-model.trim="QrCode"
                      class="form-control form-control-lg h4"
                    />
                  </div>
                  <div
                    class="text-danger text-sm"
                    style="display: block"
                    v-if="isShowQrCodeAlert"
                  >
                    <strong>โปรดแสกน QR Code</strong>
                  </div>
                </div>
                <!-- button -->
                <div class="button-row d-flex mt-4">
                  <argon-button
                    type="button"
                    color="light"
                    variant="gradient"
                    class="js-btn-prev"
                    @click="clearData"
                    >ล้างข้อมูล</argon-button
                  >
                  <argon-button
                    type="button"
                    color="primary"
                    size="md"
                    variant="gradient"
                    class="ms-auto"
                    v-if="isShowStartRetagBtn"
                    @click="startRetag"
                    >เริ่มการ Retag Qr Code</argon-button
                  >
                  <argon-button
                    type="button"
                    color="primary"
                    size="md"
                    variant="gradient"
                    class="ms-auto"
                    v-if="isShowRetagBtn"
                    @click="checkSaveRetag"
                    >บันทึก Retag Qr Code</argon-button
                  >
                </div>

                <!-- Matched Success -->
                <div class="mb-0 my-4" v-if="isShowNotification">
                  <argon-alert
                    icon="ni ni-check-bold ni-md"
                    :dismissible="true"
                  >
                    <strong>Success!</strong> {{ msgHeader }}
                    <hr class="horizontal dark m-0 my-2" />
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      Tag Barcode: {{ BarcodeResult }}
                      <br />
                      จำนวน QR Code: {{ TotalQrCode }}
                      <br />
                      รหัสสินค้า: {{ ProductCode }}
                      <br />
                      ชื่อสินค้า: {{ ProductName }}
                      <br />
                      ชนิดถุง: {{ BagType }}
                      <br />
                      สถานะ Tag Barcode: {{ $t(TagStatus) }}
                      <br />
                      วันที่ทำรายการ: {{ UpdatedAt }}
                    </p>
                  </argon-alert>
                </div>
                <!-- Previous Tag Detail -->
                <div
                  v-if="isShowPreviousTagDetail"
                  class="mb-0 bg-gray-200 border-radius-lg p-2 my-4"
                >
                  <div class="d-sm-flex align-items-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      ข้อมูล Tag, QR Code ก่อนย้าย:
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      QR Code:
                    </p>
                    <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                      {{ PreviousQrCode }}
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      รหัส Tag:
                    </p>
                    <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                      {{ PreviousBarcode }}
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      ชนิดถุง:
                    </p>
                    <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                      {{ PreviousBagType }}
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      สถานะ:
                    </p>
                    <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                      {{ $t(PreviousTagStatus) }}
                    </p>
                  </div>
                </div>
                <!-- QR Code Detail -->
                <div v-if="isShowCodeDetail">
                  <div
                    class="d-flex align-items-center justify-content-center mb-0 bg-gray-100 border-radius-lg p-2 my-4"
                  >
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      จำนวน QR Code:
                    </p>
                    <p
                      class="ms-auto text-primary h3 font-weight-bold my-auto me-3"
                    >
                      {{ TotalQrCode }}
                    </p>
                  </div>
                  <div class="mb-0 bg-gray-100 border-radius-lg p-2 my-4">
                    <div class="d-sm-flex align-items-center">
                      <p class="text-sm font-weight-bold my-auto ps-sm-2">
                        ข้อมูล Tag Barcode ใหม่ (ย้ายเข้า):
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <p class="text-sm font-weight-bold my-auto ps-sm-2">
                        สถานะ:
                      </p>
                      <p
                        class="ms-auto text-primary h6 font-weight-bold my-auto me-3"
                      >
                        {{ $t(TagStatus) }}
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <p class="text-sm font-weight-bold my-auto ps-sm-2">
                        ชนิดถุง:
                      </p>
                      <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                        {{ BagType }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- Product Detail -->
                <div
                  v-if="isShowCodeDetail"
                  class="mb-0 bg-gray-100 border-radius-lg p-2 my-4"
                >
                  <div class="d-sm-flex align-items-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      รายละเอียดสินค้า (Product):
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      รหัส:
                    </p>
                    <p class="ms-auto text-sm font-weight-bold my-auto me-3">
                      {{ ProductCode }}
                    </p>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <p class="text-sm font-weight-bold my-auto ps-sm-2">
                      ชื่อ:
                    </p>
                    <p
                      class="ms-auto text-primary h6 font-weight-bold my-auto me-3"
                    >
                      {{ ProductName }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- section qrcode list -->
          <div class="col-lg-6 my-2">
            <div class="card">
              <div class="p-3 mx-2 card-header d-flex h5">
                รายการ QR Code ที่สแกนได้
              </div>
              <div class="p-3 mx-2 pt-0 card-body">
                <!-- <h6 class="mb-0 text-center">รายการ QR Code ที่สแกนได้</h6> -->
                <hr class="my-0 horizontal dark" />
                <div class="col-12 h6">{{ qrCodeSelectedString }}</div>
              </div>
            </div>
          </div>
          <!-- section how to use -->
          <div class="col-lg-6 my-2">
            <div class="card">
              <div
                class="p-3 mx-4 text-center card-header d-flex justify-content-center"
              >
                <div
                  class="icon icon-shape icon-md shadow text-center border-radius-lg bg-gradient-success"
                >
                  <i
                    class="opacity-10 ni ni-single-copy-04"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="p-3 pt-0 mx-2 card-body">
                <h6 class="mb-0 text-center">วิธีการใช้งาน</h6>
                <hr class="my-3 horizontal dark" />
                <div class="">
                  <ul class="list-unstyled">
                    <li class="h6">
                      สามารถทำรายการได้เฉพาะ Tag Barcode ที่ยังไม่เสร็จสิ้น
                      (Finish) การประกอบกับถุงนอกเท่านั้น
                    </li>
                    <li>
                      1. ผู้ใช้งานสแกน TAG ใหม่ที่ต้องการย้าย QR Code ไปอยู่
                    </li>
                    <li>
                      2. กดปุ่ม "เริ่มต้น Retag Qr Code" เพื่อเริ่ม แสกน QR Code
                      ที่ต้องการย้าย
                      <ul>
                        <li>
                          สแกน QR Code ตัวที่ต้องการย้าย จนครบจำนวนที่ต้องการ
                          (แต่ครั้งละไม่เกิน 100 ดวง)
                        </li>
                        <li>
                          เงื่อนไข: Qr Code ที่ย้ายได้ -
                          ถุงต้องเป็นชนิดเดียวกันกับ Tag Barcode ใหม่, สถานะ
                          ยังไม่ Finish ประกอบถุง
                        </li>
                        <li>
                          ถ้าหากว่า QR Code ใน Tag Barcode ใหม่ ครบ 100
                          แล้วระบบจะแจ้งเตือน 1 ครั้ง
                          แต่ผู้ใช้งานสามารถเลือกที่จะทำรายการต่อได้
                        </li>
                      </ul>
                    </li>
                    <li>3. กดปุ่ม "ล้างข้อมูล" เพื่อล้างข้อมูลที่กรอกไว้</li>
                    <li>
                      4. กดปุ่ม "บันทึก Retag Qr Code" เพื่อบันทึกย้าย QR Code
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios"
import { ref, onMounted, reactive } from "vue"
import { useForm, useField } from "vee-validate"
import { useI18n } from "vue-i18n"
import { useRouter } from "vue-router"
import { object, string, setLocale, lazy } from "yup"
import Swal from "sweetalert2"
import getDateFormat from "@/includes/getDateFormat"

import ArgonLabel from "@/components/ArgonLabel.vue"

import ArgonButton from "@/components/ArgonButton.vue"
import ArgonAlert from "@/components/ArgonAlert.vue"

export default {
  name: "ReTagQrCodes",
  components: {
    ArgonButton,
    ArgonAlert,
    ArgonLabel,
  },
  data() {
    return {}
  },
  setup(props, ctx) {
    const { t } = useI18n({})
    const router = useRouter()

    var ProductCode = ref(null)
    var ProductName = ref(null)
    var TagStatus = ref(null)
    var TotalQrCode = ref(null)
    var BagType = ref(null)
    var UpdatedAt = ref(null)
    var msgHeader = ref(null)

    //show
    var isShowCodeDetail = ref(false)
    var isShowPreviousTagDetail = ref(false)
    var isShowRetagBtn = ref(false)
    var isShowNotification = ref(false)
    var isShowAlertQrCode = ref(false)
    var isShowQrCodeSelect = ref(false)
    var isShowQrCodeAlert = ref(false)
    var isShowStartRetagBtn = ref(false)
    var isDisabledBarcode = ref(false)
    var isShowAddQrCodeBtn = ref(false)
    var Barcode = ref(null)
    var BarcodeResult = ref(null)
    var QrCode = ref(null)
    var PreviousBarcode = ref(null)
    var PreviousTagStatus = ref(null)
    var PreviousBagType = ref(null)
    var PreviousQrCode = ref(null)

    var qrCodeSelected = ref([])
    var qrCodeSelectedString = ref(null)
    var newQrCodeSelectedAmount = ref(0)
    const qrCodeLimit = 100
    const qrCodeLimitAll = 100

    const { handleSubmit } = useForm({
      validationSchema: schema,
    })

    function unAuthorized() {
      localStorage.removeItem("curAuthen")
      localStorage.removeItem("perms")
      router.push("/login")
    }

    function setPreviousEmpty() {
      PreviousBagType.value = null
      PreviousBarcode.value = null
      PreviousTagStatus.value = null
      PreviousQrCode.value = null
    }

    function focusOnBarCode() {
      const el = document.getElementById("Barcode")
      el.focus()
    }

    function unFocusOnBarCode() {
      const el = document.getElementById("Barcode")
      el.blur()
    }

    function focusOnQrCode() {
      // const el = document.getElementById("QrCode")
      // el.focus()
      const qrCodeInput = document.getElementById("QrCode"); 
      if (qrCodeInput) {
        qrCodeInput.focus();
      }
    }

    onMounted(() => {
      focusOnBarCode()
    })

    const schema = lazy(() =>
      object({
        Barcode: string()
          .required(() => t("errors.required", { field: t("Barcode") }))
          .min(18, t("errors.min", { length: 18 }))
          .max(50, t("errors.max", { length: 50 })),
      })
    )

    document.addEventListener("DOMContentLoaded", function () {
      document.addEventListener('hide.bs.modal', function (event) {
        if (document.activeElement) {
          document.activeElement.blur();
        }
      });
    });

    function checkSaveRetag() {
      if (Barcode.value == null) {
        showAlertNoBarcode()
        return
      }
      if (Barcode.value == 0) {
        showAlertNoBarcode()
        return
      }
      showAlertConfirmSaveRetag()
    }

    function startRetag() {
      if (Barcode.value == null) {
        showAlertNoBarcode()
        return
      }
      if (Barcode.value == 0) {
        showAlertNoBarcode()
        return
      }
      setTimeout(function () {
        focusOnQrCode()
      }, 10)
      isShowQrCodeSelect.value = true
      isShowAddQrCodeBtn.value = true
      isShowStartRetagBtn.value = false
    }

    function addQrCode() {
      QrCode.value = null
      focusOnQrCode()
    }

    function addTempQrCode() {
      qrCodeSelected.value.push(QrCode.value)
      qrCodeSelectedString.value = qrCodeSelected.value.join(",")
      TotalQrCode.value++
      newQrCodeSelectedAmount.value++
      QrCode.value = null
    }

    function removeTempQrCode(index) {
      qrCodeSelected.value.splice(index, 1)
      qrCodeSelectedString.value = qrCodeSelected.value.join(",")
      TotalQrCode.value--
      newQrCodeSelectedAmount.value--
      QrCode.value = null
    }

    //checkExitQrCodeDiffBagType
    function checkExitQrCodeDiffBagType() {
      if (QrCode.value == null || QrCode.value == 0 || QrCode.value == "") {
        showAlertNoQrCode()
        return false
      }

      //check if in temp
      if (qrCodeSelected.value.length > 0) {
        //check duplicate
        let checkExit = qrCodeSelected.value.includes(QrCode.value)
        if (checkExit) {
          showAlertQrCodeExitComfirmRemove()
          return false
        }
      }else{
        return true
      }
    }

    function checkExitQrCode() {
      if (QrCode.value == null || QrCode.value == 0 || QrCode.value == "") {
        showAlertNoQrCode()
        return
      }

      //check if in temp
      if (qrCodeSelected.value.length > 0) {
        //check duplicate
        let checkExit = qrCodeSelected.value.includes(QrCode.value)
        if (checkExit) {
          showAlertQrCodeExitComfirmRemove()
        } else {
          addTempQrCode()
        }
      } else {

        addTempQrCode()
      }
    }

    const saveRetag = handleSubmit((values) => {
      const token = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      const objStart = {
        TagCode: Barcode.value,
        QrCode: qrCodeSelectedString.value,
        UpdatedBy: uid,
      }
      let res = axios({
        method: "put",
        url: "retagQrCodes/" + Barcode.value,
        data: objStart,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          clear()
          let rs = response.data.data
          BarcodeResult.value = rs.TagCode
          Barcode.value = rs.TagCode
          ProductName.value = rs.ProductName
          ProductCode.value = rs.ProductCode
          TagStatus.value = rs.TagStatus
          TotalQrCode.value = rs.TotalQrCode
          BagType.value = rs.BagType
          UpdatedAt.value = ""
          if (rs.UpdatedAt != null) {
            UpdatedAt.value = getDateFormat(rs.UpdatedAt)
          }
          isShowNotification.value = true
          msgHeader.value = "Retag QR Code สำเร็จ"
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          showAlertError()
          return false // return false to stop the form submission
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
      //save
    }, onInvalidSubmit)

    async function getTagCode() {
      if (Barcode.value) {
        let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
        let group = JSON.parse(localStorage.getItem("curAuthen")).groups
        let res = await axios({
          method: "get",
          url: "tagTransactionByTagCode/" + Barcode.value,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
          .then((response) => {
            let rs = response.data.data
            ProductCode.value = rs.ProductCode
            ProductName.value = rs.ProductName
            TagStatus.value = rs.TagStatus
            TotalQrCode.value = rs.TotalQrCode
            BagType.value = rs.BagType
            return rs
          })
          .catch((error) => {
            if (error.response.status === 401) {
              unAuthorized()
              return
            }
            Swal.fire("ไม่มีรหัส Barcode " + Barcode.value + " ในระบบ")
            setTimeout(function () {
              isDisabledBarcode.value = false
              clear()
            }, 10)
            return false // pass to finish
          })
          .then((rs) => {
            isShowNotification.value = false
            isDisabledBarcode.value = true
            showBtnByTagGroupTwo(TagStatus.value)
            // if (group == "ALL" || group == "STA3") {
            //   showBtnByTagGroupThree(TagStatus.value)
            // } else if (group == "STA2") {
            //   showBtnByTagGroupTwo(TagStatus.value)
            // } else {
            //   showBtnByTagGroupNoList()
            // }
            return rs // for await purpose
          })
        return res
      } else {
        showAlertNoBarcode()
        isDisabledBarcode.value = false
      }
    }

    function showBtnByTagGroupTwo(tagstatus) {
      if (tagstatus != null) {
        switch (tagstatus) {
          case "Ready":
            isShowRetagBtn.value = false
            isShowCodeDetail.value = true
            isShowPreviousTagDetail.value = false
            isShowAlertQrCode.value = false
            isShowQrCodeSelect.value = false
            isShowStartRetagBtn.value = true
            break
          case "Mprostart":
            isShowRetagBtn.value = false
            isShowCodeDetail.value = true
            isShowPreviousTagDetail.value = false
            isShowAlertQrCode.value = false
            isShowQrCodeSelect.value = false
            isShowStartRetagBtn.value = true
            break
          default:
            isShowRetagBtn.value = false
            isShowCodeDetail.value = true
            isShowPreviousTagDetail.value = false
            isShowAlertQrCode.value = false
            isShowQrCodeSelect.value = false
            isShowStartRetagBtn.value = false
            break
        }
      }
    }

    function showBtnByTagGroupNoList() {
      isShowRetagBtn.value = false
      isShowCodeDetail.value = true
      isShowPreviousTagDetail.value = false
      isShowAlertQrCode.value = false
      isShowQrCodeSelect.value = false
      isShowStartRetagBtn.value = false
    }

    function formatQrCode(qrcode) {
      var code = ""
      if (qrcode != null && qrcode != "") {
        var qrSplit = qrcode.split("=")
        //take last string
        code = qrSplit[qrSplit.length - 1]
      } else {
        showAlertNoQrCode()
      }
      return code
    }

    function checkQrCode() {
      var qrcode = formatQrCode(QrCode.value)
      QrCode.value = qrcode
      //get Correct qr code value format
      if (Number(newQrCodeSelectedAmount.value) == Number(qrCodeLimit)) {
        showAlertNewQrCodeLimit()
        return
      }

      if (Number(TotalQrCode.value) == qrCodeLimitAll) {
        showAlertQrCodeLimit()
        return
      }
      getQrCode()
      return true
    }

    async function getQrCode() {
      if (QrCode.value) {
        const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
        const group = JSON.parse(localStorage.getItem("curAuthen")).groups
        let res = await axios({
          method: "get",
          url:
            "qrCodeRetagByCode/" +
            QrCode.value +
            "/" +
            Barcode.value +
            "/" +
            group,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + TOKEN,
          },
        })
          .then((response) => {
            if (response.status == 207) {

              let result =  checkExitQrCodeDiffBagType()
              setTimeout(function () {
                // focusOnQrCode()
              }, 3000)
              if (QrCode.value && result==true) {
                let textDesc =  Barcode.value + " มีชนิดถุงคือ <b><u>" + BagType.value + "</u></b>  <br> แต่ QR Code ที่เลือกมีชนิดถุงคือ <b><u>" + response.data.data + "</u></b> <br>  ต้องการ Retag Qr Code หรือไม่?"
                showAlertConfirmQrCodeNotMatch(textDesc)
                return
              }
              return
            }
            if (response.status == 226) {
              //มีข้อมูลในระบบแล้ว
              showAlertErrorDuplicate()
              return false
            }
            if (
              response.status == 202 ||
              response.status == 203 ||
              response.status == 401
            ) {
              //สถานะ QR Code ไม่สามารถใช้งานได้ เช่น deleted, activated แล้ว
              showAlertErrorCannotUse(response.data.message)
              return false
            }
            if(response.data.data == null){
              showAlertErrorNotFound()
              return false
            }
            let rs = response.data.data
            PreviousBagType.value = rs.BagType
            PreviousBarcode.value = rs.TagCode
            PreviousTagStatus.value = rs.TagStatus
            PreviousQrCode.value = QrCode.value
            checkExitQrCode()
            isShowPreviousTagDetail.value = true
            isShowRetagBtn.value = true
            return response
          })
          .catch((error) => {
            if (error.response.status === 401) {
              unAuthorized()
              return
            }
            // 404 not found
            showAlertErrorNotFound()
            return false // pass to finish
          })
          .then((rs) => {
            return rs // for await purpose
          })
        return res
      } else {
        // showAlertNoQrCodeValue()
        setPreviousEmpty()
        focusOnQrCode()
      }
    }

    let isInvalidSubmit = false
    function onInvalidSubmit({ values, errors, results }) {
      isInvalidSubmit = true
    }

    function clearData() {
      clear()
      setTimeout(function () {
        focusOnBarCode()
      }, 1)
    }

    function clear() {
      Barcode.value = ""
      QrCode.value = ""
      setPreviousEmpty()
      isShowRetagBtn.value = false
      isShowCodeDetail.value = false
      isShowPreviousTagDetail.value = false
      isShowAlertQrCode.value = false
      isShowNotification.value = false
      isShowQrCodeSelect.value = false
      isShowQrCodeAlert.value = false
      isShowStartRetagBtn.value = false
      isDisabledBarcode.value = false
      isShowAddQrCodeBtn.value = false
      focusOnBarCode()
      qrCodeSelected.value = []
      qrCodeSelectedString.value = ""
      newQrCodeSelectedAmount.value = 0
    }

    //showAlertConfirmQrCodeNotMatch
    function showAlertConfirmQrCodeNotMatch(textDesc) {
      Swal.fire({
        title: "ชนิดถุงไม่ตรงกัน! Retag หรือไม่? ",
        html: textDesc,
        // icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน, ต้องการ Retag Qr Code!",
        cancelButtonText: "ยกเลิก",
        willClose: () => {
          // Blur the currently focused element to avoid retaining focus
          document.activeElement?.blur();
          // Optionally, move focus to a specific element
          setTimeout(() => {
            focusOnQrCode(); // Example: Focus back on your QR code input
          }, 1000);
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // checkExitQrCode()
          addTempQrCode()
          isShowRetagBtn.value = true
          
        } else {
          isShowPreviousTagDetail.value = false
          QrCode.value = null
        }
      })
    }

    function showAlertErrorDuplicate() {
      Swal.fire({
        title: "Error!",
        text: "ข้อมูล QR Code นี้ มีอยู่ใน Tag Barcode นี้แล้ว",
        willClose: () => {
          document.activeElement?.blur();
          isShowPreviousTagDetail.value = false
          QrCode.value = null
          setTimeout(() => {
            focusOnQrCode(); 
          }, 1000);
        },
      })
    }

    function showAlertErrorCannotUse(message) {
      Swal.fire({
        title: "Error!",
        text: "ข้อมูล QR Code นี้ ไม่สามารถใช้งานได้ เนื่องจาก " + message,
        willClose: () => {
          document.activeElement?.blur();
          isShowPreviousTagDetail.value = false
          QrCode.value = null
          setTimeout(() => {
            focusOnQrCode(); 
          }, 1000);
        },
      })
    }

    function showAlertErrorNotFound() {
      Swal.fire({
        title: "Error!",
        text: "ไม่มีข้อมูล QR Code " + QrCode.value + " ในระบบ",
        willClose: () => {
          document.activeElement?.blur();
          isShowPreviousTagDetail.value = false
          QrCode.value = null
          setTimeout(() => {
            focusOnQrCode(); 
          }, 1000);
        },
      })
    }

    function showAlertConfirmSaveRetag() {
      Swal.fire({
        title: "ยืนยันบันทึกข้อมูล?",
        text:
          "มีข้อมูล QR Code รวมทั้งหมด จำนวน " + TotalQrCode.value + " รายการ",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน, บันทึกข้อมูล Retag Qr Code!",
        cancelButtonText: "ยกเลิก",
        willClose: () => {
          document.activeElement?.blur();
        },
      }).then((result) => {
        if (result.isConfirmed) {
          saveRetag()
        }
      })
    }
    function showAlertQrCodeExitComfirmRemove() {
      Swal.fire({
        title: "เลือก QR Code นี้แล้ว!",
        text:
          "ต้องการลบข้อมูล QR Code " + QrCode.value + " ออกจากรายการที่เลือก?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน, ลบข้อมูล Qr Code ออก!",
        cancelButtonText: "ยกเลิก",
        willClose: () => {
          // Blur the currently focused element to avoid retaining focus
          document.activeElement?.blur();
          // Optionally, move focus to a specific element
          setTimeout(() => {
            focusOnQrCode(); // Example: Focus back on your QR code input
          }, 1000);
        },
      }).then((result) => {
        if (result.isConfirmed) {
          removeTempQrCode()
          return
          // return true
        } else {
          isShowPreviousTagDetail.value = false
          QrCode.value = null
          // return fasle
        }
      })
    }

    function showAlertNoBarcode() {
      Swal.fire("กรุณากรอกข้อมูล Barcode")
    }

    function showAlertNoQrCodeValue() {
      Swal.fire("กรุณากรอกข้อมูล QR Code")
    }

    function showAlertNewQrCodeLimit() {
      Swal.fire(
        "Limit!",
        "ไม่สามารถเลือก QR Code ได้เกิน " + qrCodeLimit + " รายการ",
        ""
      )
    }

    function showAlertQrCodeLimit() {
      Swal.fire({
        title: "QR Code Limit!",
        text: "QR Code ทั้งหมดเกิน " + qrCodeLimit + " รายการแล้ว",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน, ต้องการทำรายการต่อ!",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          getQrCode()
          return
        } else {
          isShowPreviousTagDetail.value = false
          QrCode.value = null
          focusOnQrCode()
          return
        }
      })
    }

    function showAlertError() {
      Swal.fire({
        title:  "Error!",
        text:  "เกิดข้อผิดพลาด ไม่สามารถทำรายการได้! กรุณาตรวจสอบ Barcode " +
          Barcode.value +
          " ในระบบ ว่ามีอยู่จริง!",
        willClose: () => {
          document.activeElement?.blur();
        },
      })
    }

    function showAlertNoQrCode() {
      Swal.fire({
        title: "Not Found!",
        text: "ไม่มีข้อมูล QR Code " + QrCode.value + "ในระบบ",
        willClose: () => {
          document.activeElement?.blur();
        },
      })
    }

    //showAlertNoQrCode

    return {
      t,
      ProductName,
      ProductCode,
      TagStatus,
      TotalQrCode,
      getTagCode,
      startRetag,
      checkSaveRetag,
      addQrCode,
      checkQrCode,
      clear,
      clearData,
      isInvalidSubmit,
      isShowRetagBtn,
      isShowCodeDetail,
      isShowPreviousTagDetail,
      isShowNotification,
      isShowAlertQrCode,
      isDisabledBarcode,
      Barcode,
      BarcodeResult,
      BagType,
      UpdatedAt,
      QrCode,

      msgHeader,
      isShowQrCodeSelect,
      isShowQrCodeAlert,
      isShowStartRetagBtn,
      isShowAddQrCodeBtn,
      qrCodeSelectedString,
      PreviousBarcode,
      PreviousTagStatus,
      PreviousBagType,
      PreviousQrCode,
    }
  },
}
</script>
