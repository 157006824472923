<template>
  <div class="py-2 container-fluid">
    <!-- Product -->
    <div
      class="pb-0 card-body d-flex justify-content-center align-items-center"
    >
      <div class="col-12 col-md-12 col-xl-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">ตั้งค่า Products</h5>
                <p class="mb-0 text-sm">
                  ข้อมูลสินค้าทั้งหมด ({{ pagination.total }} รายการ),
                  เรียงลำดับตามสถานะสินค้าเปิดใช้งาน, รหัสสินค้า
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <argon-button
                    color="primary"
                    variant="gradient"
                    class="mb-0 sm"
                    type="button"
                    v-if="isShowAddNewProduct"
                    @click.prevent="checkNewProduct"
                    >+&nbsp;เพิ่มสินค้าใหม่
                  </argon-button>
                </div>
              </div>
            </div>
            <!-- insert new products  -->
            <div
              class="py-0 col-lg-6 col-md-12 col-sm-12 card-body justify-content-center align-items-center"
              v-if="isShowNewProduct"
            >
              <div class="d-flex py-0 mt-3">
                <div>
                  <ul class="list-group">
                    <li
                      class="px-0 border-0 list-group-item list-group-item align-items-center"
                    >
                      <h6>
                        สินค้าใหม่ จำนวน
                        {{ newProductData.products.length }} รายการ
                      </h6>
                    </li>
                    <li
                      v-for="item in newProductData.products"
                      :key="item.ProductCode"
                      class="px-0 border-0 list-group-item list-group-item align-items-center"
                    >
                      <div class="px-0">
                        <label class="form-check-label">
                          <h6 class="mb-0 text-sm">
                            <span class="text-primary h5"
                              >{{ item.ProductNo }}.
                            </span>
                            <span class="px-1"></span>
                            {{ item.ProductName }}
                          </h6>

                          <span class="px-3"></span>
                          รหัส: {{ item.ProductCode }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex">
                <argon-button
                  type="button"
                  color="light"
                  variant="gradient"
                  class="js-btn-prev"
                  @click="cancelSaveNewProduct"
                  >ยกเลิก</argon-button
                >
                <argon-button
                  type="button"
                  color="primary"
                  size="md"
                  variant="gradient"
                  class="ms-auto"
                  v-if="isShowSaveNewProductBtn"
                  @click="saveNewProduct"
                  >บันทึกเพิ่มสินค้า</argon-button
                >
              </div>
            </div>
            <hr class="my-3 horizontal dark" />
            <!-- search  -->
            <div
              class="d-flex py-0 mt-3 justify-content-center align-items-center"
              v-if="isShowSearchProduct"
            >
              <div class="col-lg-6 col-sm-6 d-flex">
                <div class="input-group">
                  <input
                    v-model.trim="searchQuery"
                    type="text"
                    maxlength="50"
                    class="form-control form-control-sm"
                    size="120"
                    placeholder="Search here..."
                  />

                  <div>
                    <argon-button
                      color="dark"
                      variant="outline"
                      class="mb-0"
                      type="button"
                      @click.prevent="handleSearch"
                    >
                      ค้นหา
                    </argon-button>
                  </div>

                  <div>
                    <argon-button
                      color="dark"
                      variant="outline"
                      class="mb-0"
                      type="button"
                      @click.prevent="clearSearch"
                    >
                      ล้างข้อมูล
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- end search -->
          </div>
          <div class="card-body">
            <!-- Search -->

            <h6 class="text-xs text-uppercase text-body font-weight-bolder">
              เปิดใช้งาน
            </h6>
            <!-- check  -->
            <div class="col-12">
              <div
                v-for="item in tableData.products"
                :key="item.ProductCode"
                class="px-0 border-0 list-group-item list-group-item align-items-center"
              >
                <div class="d-flex">
                  <div class="col-3">
                    <div class="d-flex">
                      <div class="form-check form-switch ps-0">
                        <input
                          id="action"
                          name="action"
                          class="form-check-input ms-0"
                          type="checkbox"
                          :checked="item.Active"
                          @click.prevent="handleActive(item.ID, item.IsActive)"
                        />
                      </div>
                        <label class="form-check-label p-3">
                          <h6 class="mb-0 text-sm">{{ item.ProductName }}</h6>
                          รหัส: {{ item.ProductCode }}
                        </label>
                      </div>
                    </div>
                    <div class="cal-4">
                      <label class="form-check-label p-3" >
                        <h6 class="mb-0 text-sm">ชนิดถุง:  {{ item.BagType?item.BagType:'-' }}</h6>
                        Destinations: {{ item.DestinationList?item.DestinationList:'-' }}
                      </label>
                    </div>
                    <div class="col-3 ms-auto">
                      <argon-button
                        color="dark"
                        variant="outline"
                        class="mb-0 me-2"
                        type="button"
                        @click.prevent="handleEditBagType(item.ID, item.BagType)"
                        ><i class="fas fa-user-edit text-info"></i> ชนิดถุง
                      </argon-button>
                      <argon-button
                        color="dark"
                        variant="outline"
                        class="mb-0"
                        type="button"
                        @click.prevent="handleEditDestination(item.ProductCode, item.DestinationList)"
                        ><i class="fas fa-user-edit text-primary"></i> Destinations
                      </argon-button>
                    </div>
                </div>
                
              </div>
            </div>
          </div>
          <!-- load more -->
          <div
            class="d-flex justify-content-center align-items-center px-4 py-0 mt-3"
          >
            <div class="">
              <argon-button
                color="dark"
                variant="outline"
                class="mb-0"
                type="button"
                :disabled="isDisabledLoadMoreBtn"
                @click.prevent="handleSearchMore"
                >Load more ...
              </argon-button>
              <div class="py-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal 1 -->
  <div
    id="modalBagType"
    class="modal fade"
    tabindex="-1"
    :inert="!isModalBagTypeVisible"
  >
    <div class="modal-dialog mt-lg-10">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="ModalLabel" class="modal-title">
            เลือก ชนิดถุง (Bag Type) 
          </h5>
          <span>(เลือกได้ 1 รายการ)</span>
        </div>
        <div class="modal-body">
          <div v-for="item in bagTypeData.bagTypes" :key="item.BagType">
            <div class="form-check">
              <input
                :id="'chkBagType_'+item.ID"
                class="form-check-input"
                type="radio"
                name="name"
                :checked="item.checkBagType"
                @change="handleChangeBagType(item.ID, item.BagType)"
              />
              <label class="custom-control-label h6">
                {{ item.BagType }}
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn bg-gradient-light btn-sm"
            data-bs-dismiss="modal"
            @click.prevent="handleCloseBagType"
          >
            ยกเลิก
          </button>
          <button
            type="button"
            class="btn bg-gradient-primary btn-sm"
             @click.prevent="handleSaveBagType"
          >
            บันทึกข้อมูล
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- modal 2 -->
  <div
    id="modalDestination"
    class="modal fade"
    tabindex="-1"
    :inert="!isModalDestinationVisible"
  >
    <div class="modal-dialog mt-lg-10">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="ModalLabel" class="modal-title">
            เลือก Destinations
          </h5>
          <span>(เลือกได้ 1 รายการ)</span>
        </div>
        <div class="modal-body">
          <div v-for="item in destinationData.destinations" :key="item.DestinationCode">
              <div class="form-check">
                <input
                  :id="'chkDestination_'+item.ID"
                  class="form-check-input"
                  type="radio"
                  name="chkDestination"
                  :checked="item.checkDestination"
                  @change="handleChangeDestination(item.ID, item.DestinationCode)"
                />
                <label class="custom-control-label h6" >
                 {{ item.DestinationCode }} - {{ item.DestinationName }} 
                 <span v-if="item.Remark !=''"> ({{ item.Remark }})</span>
                 <span v-if="item.IsActive==2"> - [ปิดใช้งาน]</span>
                </label>
              </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn bg-gradient-light btn-sm"
            data-bs-dismiss="modal"
            @click.prevent="handleCloseDestination"
          >
            ยกเลิก
          </button>
          <button
            type="button"
            class="btn bg-gradient-primary btn-sm"
             @click.prevent="handleSaveDestination"
          >
            บันทึกข้อมูล
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { ref, onMounted, reactive , watch} from "vue"
import ArgonButton from "@/components/ArgonButton.vue"
import ArgonPagination from "@/components/ArgonPagination.vue"
import ArgonPaginationItemTable from "@/components/ArgonPaginationItemTable.vue"
import ArgonSwitch from "@/components/ArgonSwitch.vue"
import { useRouter } from "vue-router"
import Swal from "sweetalert2"
import { Modal } from "bootstrap"

export default {
  name: "ProductTables",
  props: {},
  components: {
    ArgonButton,
    ArgonPagination,
    ArgonPaginationItemTable,
    ArgonSwitch,
  },
  setup(props, ctx) {
    const router = useRouter()
    var pagination = reactive({
      perPageOptions: [5, 10, 25, 50, 100],
      perPage: 10,
      page: 1,
      from: 1,
      to: 1,
      total: 1,
      pageCount: 1,
    })
    var searchQuery = ref("")
    var isDisabledLoadMoreBtn = ref(false)
    var apiProductData = reactive([])
    var newProductData = reactive({
      products: [],
    })
    var isShowAddNewProduct = ref(true)
    var isShowSearchProduct = ref(true)
    var isShowNewProduct = ref(false)
    var newproducts = []
    var isShowSaveNewProductBtn = ref(false)

    var modalBagType = null
    var modalDestination = null
    var action = ref("")
    let destinationList = [] //for checkbox
    let currentProductCode = ""
    let currentProductID = "" 
    let selectedBagType = ""
    let selectedDestination = ""

    var destinationData = reactive({
      destinations: [],
    })
    var productDestinationData = reactive({
      productDestinations: [],
    })
    var bagTypeData = reactive({
      bagTypes: [],
    })
    let isModalBagTypeVisible = ref(false);
    let isModalDestinationVisible = ref(false);

    onMounted(() => {
      fetchResult()
      // getAllDataDestination()
      // getAllDataBagType()
      // modal //important
      if (typeof document !== "undefined") {
        var modalElement = document.getElementById("modalDestination")
        if (modalElement) {
          modalDestination = new Modal(modalElement)
        }

        var modalElementBagType = document.getElementById("modalBagType")
        if (modalElementBagType) {
          modalBagType = new Modal(modalElementBagType)
        }
      }
    })

    // modal
    document.addEventListener("DOMContentLoaded", function () {
          document.addEventListener('hide.bs.modal', function (event) {
              if (document.activeElement) {
                  document.activeElement.blur();
                  clearCheckDestination()
                  clearCheckBagType()
              }
          });
      });

    function handleCloseBagType() {
      currentProductCode = ""
      currentProductID = ""
      selectedBagType = ""
      isModalBagTypeVisible.value = false;
      modalBagType.hide()
    }
    function handleCloseDestination() {
      currentProductCode = ""
      selectedDestination = ""
      destinationList = []
      isModalDestinationVisible.value = false;  
      modalDestination.hide()
    }

    function handleEditBagType(productID, bagType) {  
      getAllDataBagType(bagType)
      setTimeout(function () {
      }, 3000)
      isModalBagTypeVisible.value = true;
      action.value = "edit"
      currentProductID = productID
      selectedBagType = bagType
      modalBagType.show()
    }
    function handleEditDestination(productCode, dList) {
      getAllDataDestination(dList)
      setTimeout(function () {
      }, 3000)
      selectedDestination = ""
      isModalDestinationVisible.value = true;  
      action.value = "edit"
      currentProductCode = productCode
      //destinationList = only one
      selectedDestination = dList
      destinationList =[]
      destinationList.push(dList)
      modalDestination.show()
    }

    function clearCheckDestination() {
      destinationData.destinations.forEach((element) => {
        element.checkDestination = false
      })
      destinationData.destinations = []
    }

    function clearCheckBagType() {
      bagTypeData.bagTypes.forEach((element) => {
        element.checkBagType = false
      })
      bagTypeData.bagTypes = []
    }

    function handleChangeDestination(id, destinationCode) {
      let chk = document.getElementById("chkDestination_" + id) 
      destinationList =[]
      if (chk.checked) {
        destinationList.push(destinationCode)
        selectedDestination = destinationCode
      } else {
        destinationList =[]
      }
    }

    function handleChangeBagType(id, bagType) {
      let chk = document.getElementById("chkBagType_" + id)
      if (chk.checked) {
        selectedBagType = bagType
      } else {
        selectedBagType = ""
      }
    }

    function getAllDataBagType(bagType) {
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      let res = axios({
        method: "get",
        url: "bagTypes-active",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          bagTypeData.bagTypes = []
          let dataRes = []
          if (response.data.data) {
            response.data.data.forEach((element) => {
              dataRes = element
              dataRes.checkBagType = false
              if (element.BagType == bagType) {
                dataRes.checkBagType = true
              } else {
                dataRes.checkBagType = false
              }
              bagTypeData.bagTypes.push(dataRes)
            })
          }
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          return false // pass to finish
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
    } 

  async  function getAllDataDestination(dList) {
      let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      let res = await axios({
        method: "get",
        url: "destinations",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          destinationData.destinations = []
          let dataRes = []
          if (response.data.data) {
            response.data.data.forEach((element) => {
              dataRes = element
              dataRes.checkDestination = false
              if (element.DestinationCode === dList) {
                dataRes.checkDestination = true
              } else {
                dataRes.checkDestination = false
              }
              destinationData.destinations.push(dataRes)
            })
          }
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          return false // pass to finish
        })
        .then((rs) => {
          //ต้องแยกกัน เพราะต้องรอให้ fetch ข้อมูลเสร็จก่อน
          destinationData.destinations.forEach((element) => {
              if (element.IsActive == 2) {
                const radios = document.getElementById(`chkDestination_${element.ID}`); 
                radios.disabled = true
              }
            })
          return rs // for await purpose
        })
      return res
    } 

    async function handleSaveDestination() {
       //condition select 1 Destination
      if (selectedDestination == "") {
        Swal.fire("Warning!", "กรุณาเลือก Destination อย่างน้อย 1 รายการ", "")
        return
      }
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      const textDestinationList = destinationList.join(", "); 
      // const textDestinationList = selectedDestination.join(", "); 
      const objDestination = {
        ProductCode: currentProductCode,
        DestinationList: textDestinationList,
        CreatedBy: uid,
        UpdatedBy: uid,
      }
      let res = await axios({
        method: "post",
        url: "productDestinations",
        data: objDestination,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            Swal.fire(
              "Success!",
              "บันทึกข้อมูล Destination สำเร็จ!",
              ""
            )

            setTimeout(function () {
              fetchResult()
            }, 10)
          } else {
            Swal.fire("Error!", "เกิดข้อผิดพลาดในการบันทึกข้อมูล", "")
          }
          currentProductCode = ""
          handleCloseDestination()
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          Swal.fire("Error!", "เกิดข้อผิดพลาดในการบันทึกข้อมูล", "")
          return false // pass to finish
        })
        .then((rs) => {
          currentProductCode = ""
          handleCloseDestination()
          return rs // for await purpose
        })
      return res
    } 
   
    async function handleSaveBagType() {
      //condition select 1 bagType
      if (selectedBagType == "") {
        Swal.fire("Warning!", "กรุณาเลือก ชนิดถุง (Bag Type)", "")
        return
      }
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      const objBagType = {
        BagType: selectedBagType,
        UpdatedBy: uid,
      }
      let res = await axios({
        method: "put",
        url: "productBagTypes/" + currentProductID,
        data: objBagType,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            Swal.fire(
              "Success!",
              "บันทึกข้อมูล Bag Type สำเร็จ!",
              ""
            )
            setTimeout(function () {
              fetchResult()
            }, 10)
          } else {
            Swal.fire("Error!", "เกิดข้อผิดพลาดในการบันทึกข้อมูล", "")
          }
          // handleCloseBagType()
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          Swal.fire("Error!", "เกิดข้อผิดพลาดในการบันทึกข้อมูล", "")
          return false // pass to finish
        })
        .then((rs) => {
          handleCloseBagType()
          return rs // for await purpose
        })
      return res
    } 
    //end modal

    function unAuthorized() {
      localStorage.removeItem("curAuthen")
      localStorage.removeItem("perms")
      router.push("/login")
    }

    function handleSearch() {
      fetchResult()
    }

    function handleSearchMore() {
      pagination.perPage = pagination.perPage + 5
      fetchResult()
      // pagination.page = pagination.page + 1
    }

    function clearSearch() {
      searchQuery.value = ""
      fetchResult()
    }

    function handleActive(id, active) {
      const token = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      if (active == 1) {
        active = 2
      } else {
        active = 1
      }
      const objActive = {
        IsActive: active,
        UpdatedBy: uid,
      }
      let res = axios({
        method: "put",
        url: "products-active/" + id,
        data: objActive,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            Swal.fire(
              "Success!",
              "บันทึกข้อมูลสำเร็จ! สินค้า" +
                response.data.data.ProductName +
                "",
              ""
            )
            setTimeout(function () {
              fetchResult()
            }, 10)
          } else {
            Swal.fire("Error!", "เกิดข้อผิดพลาดในการบันทึกข้อมูล", "")
          }
          return
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          Swal.fire("Error!", "เกิดข้อผิดพลาดในการบันทึกข้อมูล", "")
          return false
        })
      return res
    }

    function getAllDataProduct() {
      let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      let res = axios({
        method: "get",
        url: "products",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          var dataRes = []
          newproducts = []
          if (response.data.data) {
            response.data.data.forEach((element) => {
              dataRes = element
              dataRes.Active = false
              newproducts.push(dataRes)
            })
          }
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          return false // pass to finish
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
    } //end getDataProduct

    function checkNewProduct() {
      var r = getAllDataProduct()
      const promise1 = Promise.resolve(r)
      promise1.then((value) => {
        if (r) {
          getApiDataProduct()
        }
      })
    }

    var objInsert = []
    async function getApiDataProduct() {
      //get api data
      let url = ""
      const customAxios = axios.create({
        baseURL: "http://192.168.20.9:10042/",
        timeout: 5000,
      })
      var res = await customAxios({
        method: "get",
        url: "web/services/PRODUCT",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          apiProductData = response.data.data
          return rs
        })
        .catch((error) => {
          console.log(error.response)
          return false // pass to finish
        })

      // ถ้าไม่มีข้อมูล เรียกจาก file แทน
      var checkNewProduct = true
      if (apiProductData.length == 0) {
        // apiProductData = TcccProductData
        console.log("No new product!")
        checkNewProduct = false
      }

      newProductData.products = []
      var i = 0
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      if (checkNewProduct == true) {
        let allData = apiProductData.PRODUCT_GETALL_R
        if (allData.length > 0) {
          allData.forEach((element) => {
            var dataRes = []
            var result = findProduct(element["MNMFG#"])
            if (!result) {
              i++
              dataRes.ProductCode = element["MNMFG#"]
              dataRes.ProductName = element.MNDESC
              dataRes.ProductNo = i
              dataRes.UpdatedBy = uid
              newProductData.products.push(dataRes)
              objInsert.push({
                ProductCode: element["MNMFG#"],
                ProductName: element.MNDESC,
                UpdatedBy: uid,
              })
            }
          })
        }
      }
      //check if > 0 show button
      if (newProductData.products.length > 0) {
        isShowSaveNewProductBtn.value = true
      }
      isShowAddNewProduct.value = false
      isShowSearchProduct.value = false
      isShowNewProduct.value = true
      return
    }

    function findProduct(code) {
      const result = newproducts.find((element) => element.ProductCode === code)
      if (result) {
        return true
      } else {
        return false
      }
    }

    function cancelSaveNewProduct() {
      isShowAddNewProduct.value = true
      isShowSearchProduct.value = true
      isShowNewProduct.value = false
      isShowSaveNewProductBtn.value = false
    }

    async function saveNewProduct() {
      let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      let res = await axios({
        method: "post",
        url: "products-new",
        data: objInsert,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          isShowAddNewProduct.value = true
          isShowSearchProduct.value = true
          isShowNewProduct.value = false
          if (response.status == 201) {
            Swal.fire(
              "Success!",
              "บันทึกเพิ่มข้อมูลสินค้าใหม่สำเร็จ! จำนวน " +
                newProductData.products.length +
                " รายการ",
              ""
            )
          } else {
            Swal.fire("Error!", "เกิดข้อผิดพลาดในการบันทึกข้อมูล", "")
          }
          isShowSaveNewProductBtn.value = false
          setTimeout(function () {
            newProductData.products = []
            objInsert = []
            isShowSaveNewProductBtn.value = false
            fetchResult()
          }, 10)

          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          Swal.fire("Error!", "เกิดข้อผิดพลาดในการบันทึกข้อมูล", "")
          return false // pass to finish
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
    }

    var tableData = reactive({
      products: [],
    })
    async function getDataProduct() {
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      var PageOffset = 0
      if (pagination.page > 1) {
        PageOffset = pagination.page * pagination.perPage
      }
      if (PageOffset > pagination.total) {
        PageOffset = pagination.total
      }
      let Search = searchQuery.value
      if (Search == "") {
        Search = "null"
      }
      let res = await axios({
        method: "get",
        url:
          "productsPerPage/" +
          Search +
          "/" +
          pagination.perPage +
          "/" +
          PageOffset,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          var dataRes = []
          tableData.products = []
          var page = response.data.paginate
          if (response.data.data) {
            response.data.data.forEach((element) => {
              dataRes = element
              dataRes.Active = false
              dataRes.ActiveLabel = "ปิด"
              if (element.IsActive === 1) {
                dataRes.ActiveLabel = "เปิด"
                dataRes.Active = true
              }
              tableData.products.push(dataRes)
            })
          }
          pagination.from = page.RowFrom
          pagination.to = page.RowTo
          pagination.total = page.RowTotal
          pagination.pageCount = Math.ceil(page.RowTotal / pagination.perPage)
          isDisabledLoadMoreBtn.value = false
          let currentDataNumber = tableData.products.length
          if (
            currentDataNumber == pagination.total ||
            currentDataNumber > pagination.total ||
            pagination.total == 0 ||
            pagination.total == null
          ) {
            isDisabledLoadMoreBtn.value = true
          }
          return true
        })
        .catch((error) => {
          return false // pass to finish
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
    } //end getDataProduct

    async function fetchResult() {
      let success = await getDataProduct()
      if (success) {
        // console.log("fetched data success")
        // handle success
      } else {
        // console.log("fetched data failed")
        // handle error
      }
    } //end fetchResult
    return {
      tableData,
      pagination,
      searchQuery,
      isDisabledLoadMoreBtn,
      newProductData,
      isShowAddNewProduct,
      isShowSearchProduct,
      isShowNewProduct,
      isShowSaveNewProductBtn,
      handleSearch,
      handleActive,
      handleSearchMore,
      clearSearch,
      cancelSaveNewProduct,
      saveNewProduct,
      checkNewProduct,
      handleEditBagType,
      handleCloseBagType,
      handleEditDestination,
      handleCloseDestination,
      destinationData,
      bagTypeData,
      handleChangeDestination,
      handleSaveDestination,
      handleChangeBagType,
      handleSaveBagType,
      isModalBagTypeVisible,
      isModalDestinationVisible,
    }
  },
}
</script>
