<template>
  <div class="py-2 container-fluid">
    <!-- Product -->
    <div
      class="pb-0 card-body d-flex justify-content-center align-items-center"
    >
      <div class="col-12 col-md-10 col-xl-10">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">ข้อมูล Destinations</h5>
                <p class="mb-0 text-sm">
                  ข้อมูล Destinations ทั้งหมด ({{ pagination.total }} รายการ),
                  เรียงลำดับตามรหัส Destination จากน้อยไปมาก
                </p>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <argon-button
                    color="primary"
                    variant="gradient"
                    class="mb-0 sm"
                    type="button"
                    @click.prevent="handleAdd"
                    >+&nbsp;เพิ่มข้อมูล Destinations
                  </argon-button>
                </div>
              </div>
            </div>
            <hr class="my-3 horizontal dark" />
            <!-- search  -->
            <div
              class="d-flex py-0 mt-3 justify-content-center align-items-center"
              v-if="isShowSearchProduct"
            >
              <div class="col-lg-6 col-sm-6 d-flex">
                <div class="input-group">
                  <input
                    v-model.trim="searchQuery"
                    type="text"
                    maxlength="50"
                    class="form-control form-control-sm"
                    size="120"
                    placeholder="Search here..."
                  />

                  <div>
                    <argon-button
                      color="dark"
                      variant="outline"
                      class="mb-0"
                      type="button"
                      @click.prevent="handleSearch"
                    >
                      ค้นหา
                    </argon-button>
                  </div>

                  <div>
                    <argon-button
                      color="dark"
                      variant="outline"
                      class="mb-0"
                      type="button"
                      @click.prevent="clearSearch"
                    >
                      ล้างข้อมูล
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- end search -->
          </div>
          <div class="card-body">
            <!-- Search -->
            <div class="d-flex">
              <div class="col-4">
                <h6 class="text-xs text-uppercase text-body font-weight-bolder">
                  เปิดใช้งาน
                </h6>
              </div>
              <div class="col-3 form-check-label ps-3">
                 <h6 class="text-xs text-uppercase text-body font-weight-bolder">
                  หมายเหตุ
                </h6>
              </div>
              <div class="ms-auto"> </div>
            </div>
            <!-- check  -->
            <div class="col-12">
              <div
                v-for="item in tableData.destinations"
                :key="item.DestinationCode"
                class="px-0 border-0 list-group-item list-group-item align-items-center"
              >
                <div class="d-flex">
                  <div class="col-4">
                    <div class="d-flex">
                      <div class="form-check form-switch ps-0">
                        <input
                          id="action"
                          name="action"
                          class="form-check-input ms-0"
                          type="checkbox"
                          :checked="item.Active"
                          @click.prevent="handleActive(item.ID, item.IsActive)"
                        />
                      </div>
                      <div>
                        <label class="form-check-label p-3">
                          <h6 class="mb-0 text-sm">{{ item.DestinationName }}</h6>
                          รหัส: {{ item.DestinationCode }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <label class="form-check-label p-3" v-show="item.Remark!=''">
                      {{ item.Remark }} 
                    </label>
                  </div>
                  <div class="ms-auto">
                    <argon-button
                      color="dark"
                      variant="outline"
                      class="mb-0"
                      type="button"
                      @click.prevent="handleEdit(item)"
                      >แก้ไข
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- load more -->
          <div
            class="d-flex justify-content-center align-items-center px-4 py-0 mt-3"
          >
            <div class="">
              <argon-button
                color="dark"
                variant="outline"
                class="mb-0"
                type="button"
                :disabled="isDisabledLoadMoreBtn"
                @click.prevent="handleSearchMore"
                >Load more ...
              </argon-button>
              <div class="py-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal -->
  <div
    id="modalDestination"
    class="modal fade"
    tabindex="-1"
    :inert="!isModalVisible"
  >
    <div class="modal-dialog mt-lg-10">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="ModalLabel" class="modal-title">
            เพิ่ม/แก้ไข ข้อมูล Destinations
          </h5>
        </div>
        <div class="modal-body">
          <div>
            <h6>รหัส Destination <span class="text-danger text-lg">*</span></h6>
              <input
                id="DestinationCode"
                type="text"
                class="mb-3 form-control"
                maxlength="25"
                v-model.trim="DestinationCode"
              />
          </div>
          <div>
            <h6>ชื่อ Destination <span class="text-danger text-lg">*</span></h6>
              <input
                id="DestinationName"
                type="text"
                class="mb-3 form-control"
                maxlength="50"
                v-model.trim="DestinationName"
              />
          </div>
          <div>
            <h6>หมายเหตุ </h6>
              <textarea
                class="mb-3 form-control"
                maxlength="100"
                rows="2"
                v-model.trim="Remark"
              />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn bg-gradient-light btn-sm"
            data-bs-dismiss="modal"
            @click.prevent="handleCloseModal"
          >
            ยกเลิก
          </button>
          <button
            type="button"
            class="btn bg-gradient-primary btn-sm"
             @click.prevent="handleSaveModal"
          >
            บันทึกข้อมูล
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { ref, onMounted, reactive } from "vue"
import ArgonButton from "@/components/ArgonButton.vue"
import ArgonPagination from "@/components/ArgonPagination.vue"
import ArgonPaginationItemTable from "@/components/ArgonPaginationItemTable.vue"
import ArgonSwitch from "@/components/ArgonSwitch.vue"
import { useRouter } from "vue-router"
import Swal from "sweetalert2"
import { Modal } from "bootstrap"


export default {
  name: "DestinationTables",
  props: {},
  components: {
    ArgonButton,
    ArgonPagination,
    ArgonPaginationItemTable,
    ArgonSwitch,
  },
  setup(props, ctx) {
    const router = useRouter()
    var pagination = reactive({
      perPageOptions: [5, 10, 25, 50, 100],
      perPage: 10,
      page: 1,
      from: 1,
      to: 1,
      total: 1,
      pageCount: 1,
    })
    var searchQuery = ref("")
    var isDisabledLoadMoreBtn = ref(false)
    var newProductData = reactive({
      destinations: [],
    })
    var isShowSearchProduct = ref(true)
    var modal = null
    var ID = ref("")
    var DestinationCode = ref("") 
    var DestinationName = ref("")
    var Remark = ref("")

    let isModalVisible = ref(false);

    onMounted(() => {
      fetchResult()
      // modal //important
      if (typeof document !== "undefined") {
        var modalElement = document.getElementById("modalDestination")
        if (modalElement) {
          modal = new Modal(modalElement)
        }
      }
    })

    document.addEventListener("DOMContentLoaded", function () {
          document.addEventListener('hide.bs.modal', function (event) {
              if (document.activeElement) {
                  document.activeElement.blur();
              }
          });
      });

    function handleCloseModal() {
      ID.value = ""
      DestinationCode.value = ""
      DestinationName.value = ""
      Remark.value = ""
      isModalVisible.value = false
      modal.hide()
    }

    function handleAdd() {
      isModalVisible.value = true
      ID.value = ""
      DestinationCode.value = ""
      DestinationName.value = ""
      Remark.value = ""
      action.value = "create"
      modal.show()
    }

    function handleEdit(item) {
      isModalVisible.value = true
      ID.value = item.ID
      DestinationCode.value = item.DestinationCode
      DestinationName.value = item.DestinationName
      Remark.value = item.Remark
      action.value = "edit"
      modal.show()
    }

    function unAuthorized() {
      localStorage.removeItem("curAuthen")
      localStorage.removeItem("perms")
      router.push("/login")
    }

    function handleSearch() {
      fetchResult()
    }

    function handleSearchMore() {
      pagination.perPage = pagination.perPage + 5
      fetchResult()
    }

    function clearSearch() {
      searchQuery.value = ""
      fetchResult()
    }

    function handleActive(id, active) {
      const token = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      if (active == 1) {
        active = 2
      } else {
        active = 1
      }
      const objActive = {
        IsActive: active,
        UpdatedBy: uid,
      }
      let res = axios({
        method: "put",
        url: "destinations-active/" + id,
        data: objActive,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            // Swal.fire(
            //   "Success!",
            //   "บันทึกข้อมูลสำเร็จ! Destination: " +
            //     response.data.data.DestinationName +
            //     "",
            //   ""
            // )
            showSuccessActiveData(response.data.data.DestinationName)
            setTimeout(function () {
              fetchResult()
            }, 10)
          } else {
            showErrorSaveData()
          }
          return
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          showErrorSaveData()
          return false
        })
      return res
    }
    

    //handleSaveModal
    async function handleSaveModal() {
      if (DestinationCode.value == "") {
        showErrorNoCode()
        return
      }
      if (DestinationName.value == "") {
        showErrorNoName()
        return
      }
      //check duplicate
      let isDuplicate = false
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      let id  = ID.value?ID.value:"null"
      await axios({
        method: "get",
        url: "destinationCheckDuplicate/"+DestinationCode.value+"/"+id,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          if (response.data.count > 0) {
            isDuplicate = true
            showDupplicateCode()
          }
          return
        })
        .catch((error) => {
          return false
        })
      if (isDuplicate) {
        return
      }else{
        if (ID.value == "") {
          let success = await saveNewProduct()
          if (success) {
            handleCloseModal()
          }
        } else {
          let success = await saveEditProduct()
          if (success) {
            handleCloseModal()
          }
        }
      }
      
    }

    async function saveNewProduct() {
      const TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      const objInsert = {
        DestinationCode: DestinationCode.value,
        DestinationName: DestinationName.value,
        Remark: Remark.value,
        CreatedBy: uid,
        UpdatedBy: uid,
      }
      let res = await axios({
        method: "post",
        url: "destinations",
        data: objInsert,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          isShowSearchProduct.value = true
          if (response.status == 201) {
            showSuccessSaveData()
          } else {
            showErrorSaveData()
          }
          fetchResult()
          handleCloseModal()
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          showErrorSaveData()
          handleCloseModal()
          return false // pass to finish
        })
      return res
    }

    async function saveEditProduct() {
      let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      let uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      let objUpdate = {
        DestinationCode: DestinationCode.value,
        DestinationName: DestinationName.value,
        Remark: Remark.value,
        UpdatedBy: uid,
      }
      let res = await axios({
        method: "put",
        url: "destinations/" + ID.value,
        data: objUpdate,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          isShowSearchProduct.value = true
          if (response.status == 200) {
            showSuccessSaveData()
          } else {
            showErrorSaveData()
          }
          fetchResult()
          handleCloseModal()
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          showErrorSaveData()
          handleCloseModal()
          return false // pass to finish
        })
      return res
    }

    var tableData = reactive({
      destinations: [],
    })
    async function getDataDestination() {
      let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token
      var PageOffset = 0
      if (pagination.page > 1) {
        PageOffset = pagination.page * pagination.perPage
      }
      if (PageOffset > pagination.total) {
        PageOffset = pagination.total
      }
      let Search = searchQuery.value
      if (Search == "") {
        Search = "null"
      }
      let res = await axios({
        method: "get",
        url:
          "destinationPerPage/" +
          Search +
          "/" +
          pagination.perPage +
          "/" +
          PageOffset,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          var dataRes = []
          tableData.destinations = []
          var page = response.data.paginate
          if (response.data.data) {
            response.data.data.forEach((element) => {
              dataRes = element
              dataRes.Active = false
              dataRes.ActiveLabel = "ปิด"
              if (element.IsActive === 1) {
                dataRes.ActiveLabel = "เปิด"
                dataRes.Active = true
              }
              tableData.destinations.push(dataRes)
            })
          }
          pagination.from = page.RowFrom
          pagination.to = page.RowTo
          pagination.total = page.RowTotal
          pagination.pageCount = Math.ceil(page.RowTotal / pagination.perPage)
          isDisabledLoadMoreBtn.value = false
          let currentDataNumber = tableData.destinations.length
          if (
            currentDataNumber == pagination.total ||
            currentDataNumber > pagination.total ||
            pagination.total == 0 ||
            pagination.total == null
          ) {
            isDisabledLoadMoreBtn.value = true
          }
          return true
        })
        .catch((error) => {
          return false // pass to finish
        })
        .then((rs) => {
          return rs // for await purpose
        })
      return res
    } //end getDataDestination

     //show alert
    //  function showAlertTagCodeErrorNotFound() {
    //   Swal.fire({
    //     title: "Not Found!",
    //     text: "ไม่มีรหัส Barcode " + Barcode.value + " ในระบบ",
    //     willClose: () => {
    //       document.activeElement?.blur();
    //       clear()
    //       isDisabledBarcode.value = false
    //       setTimeout(() => {
    //         focusOnBarCode()
    //       }, 1000);
    //     },
    //   })
    // }

    function showErrorNoCode() {
      Swal.fire({
        title: "Error!",
        text: "กรุณากรอก รหัส Destination",
        willClose: () => {
          document.activeElement?.blur();
        },
      })
    } 

    function showErrorNoName() {
      Swal.fire({
        title: "Error!",
        text: "กรุณากรอก ชื่อ Destination",
        willClose: () => {
          document.activeElement?.blur();
        },
      })
    }

    function showDupplicateCode() {
      Swal.fire({
        title: "Error!",
        text: "รหัส Destination ซ้ำกับข้อมูลที่มีอยู่",
        willClose: () => {
          document.activeElement?.blur();
        },
      })
    } 

    function showSuccessSaveData() {
      Swal.fire({
        title: "Success!",
        text: "บันทึกข้อมูลสำเร็จ!",
        willClose: () => {
          document.activeElement?.blur();
        },
      })
    }

    function showSuccessActiveData(name) {
      Swal.fire({
        title: "Success!",
        text: "บันทึกข้อมูลสำเร็จ! Destination: " +
                name +
                "",
        willClose: () => {
          document.activeElement?.blur();
        },
      })
    } 


    function showErrorSaveData() {
      Swal.fire({
        title: "Error!",
        text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล",
        willClose: () => {
          document.activeElement?.blur();
        },
      })
    }

    //fetch data
    async function fetchResult() {
      let success = await getDataDestination()
      if (success) {
        // console.log("fetched data success")
        // handle success
      } else {
        // console.log("fetched data failed")
        // handle error
      }
    } //end fetchResult
    return {
      tableData,
      pagination,
      searchQuery,
      isDisabledLoadMoreBtn,
      newProductData,
      isShowSearchProduct,
      handleSearch,
      handleActive,
      handleSearchMore,
      clearSearch,
      saveNewProduct,
      handleCloseModal,
      handleSaveModal,
      handleEdit,
      handleAdd,
      DestinationCode,
      DestinationName,
      Remark,
      isModalVisible,

    }
  },
}
</script>