/**
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue"
import App from "./App.vue"
import store from "./store"
import router from "./router"

import "./assets/css/nucleo-icons.css"
import "./assets/css/nucleo-svg.css"
// import VueTilt from "vue-tilt.js"
import ArgonDashboard from "./argon-dashboard"

// import 'wicg-inert';

// add
import Axios from "axios"
if (process.env.NODE_ENV !== "production") {
  Axios.defaults.baseURL = "http://localhost:4700/api/" //tccc-backend localhost
} else {
  Axios.defaults.baseURL = "https://backend.qrboth.com/api/" //tccc-backend production
}
import i18n from "@/localization"

const appInstance = createApp(App)
appInstance.use(store)
appInstance.use(router)
appInstance.use(ArgonDashboard)
appInstance.use(i18n)

appInstance.mount("#app")
