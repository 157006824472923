<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">All Users</h5>
                <p class="mb-0 text-sm">
                  ข้อมูลผู้ใช้งานระบบทั้งหมด เรียงลำดับตามชื่อผู้ใช้งาน
                </p>
              </div>
            </div>
          </div>
          <div
            v-if="isShowSearch"
            class="d-flex justify-content-between align-items-center px-4 py-0 mt-3"
          >
            <!-- <div class="col-lg-2 col-sm-6">
              <select
                id="choices-page-options"
                class="form-control"
                name="choices-page-options"
              ></select>
            </div> -->
            <div class="col-lg-6 col-sm-6 d-flex">
              <div class="input-group">
                <span class="input-group-text text-body">
                  <i class="fas fa-search" aria-hidden="true"></i>
                </span>
                <input
                  v-model.trim="searchQuery"
                  type="text"
                  class="form-control"
                  placeholder="Search here..."
                />
              </div>
              <argon-button
                color="dark"
                variant="outline"
                class="mb-0"
                type="button"
                @click.prevent="handleSearch"
              >
                <div class="d-flex">
                  <span class="ni ni-zoom-split-in ni-lg me-1"></span>
                  Search
                </div>
              </argon-button>
            </div>
            <!-- end search -->
          </div>
          <!-- Reset  -->
          <div
            class="py-0 col-lg-6 col-md-12 col-sm-12 card-body justify-content-center align-items-center"
            v-if="isShowEdit"
          >
            <hr class="mt-2 horizontal dark" />
            <div class="py-0 mt-3">
              <form id="updatepass">
              <div>
                <h5 class="mb-0">
                  Username: <span class="text-primary">{{ Username }}</span>
                </h5>
                <form>
                  <div>
                    <div>
                      <h6>
                        รหัสผ่านใหม่
                        <span class="text-danger text-lg">*</span>
                      </h6>
                    </div>
                    <input
                      id="NewPassword"
                      type="password"
                      autocomplete="new-password"
                      class="form-control form-control-lg"
                      min="6"
                      maxlength="50"
                      v-model.trim="NewPassword"
                    />
                    <div v-if="isShowAlertNewPassword">
                      <span class="text-danger text-sm"
                        >รหัสผ่านใหม่จะต้องมีความยาวอย่างน้อย 6 ตัวอักษร ไม่เกิน
                        50 ตัวอักษร</span
                      >
                    </div>
                  </div>
                </form>
                <div>
                  <div>
                    <h6>
                      ยืนยันรหัสผ่านใหม่
                      <span class="text-danger text-lg">*</span>
                    </h6>
                  </div>
                  <input
                    id="ConfirmNewPassword"
                    type="password"
                    autocomplete="new-password"
                    class="form-control form-control-lg"
                    min="6"
                    maxlength="50"
                    v-model.trim="ConfirmNewPassword"
                  />
                  <div v-if="isShowAlertConfirmNewPassword">
                    <span class="text-danger text-sm"
                      >ข้อมูลยืนยันรหัสผ่านใหม่
                      ไม่ตรงกับข้อมูลรหัสผ่านใหม่</span
                    >
                  </div>

                  <!-- button -->
                  <div class="button-row d-flex mt-4">
                    <argon-button
                      type="button"
                      color="light"
                      variant="gradient"
                      class="js-btn-prev"
                      @click="clear"
                      >ยกเลิก</argon-button
                    >
                    <argon-button
                      type="button"
                      color="primary"
                      variant="gradient"
                      class="ms-auto"
                      @click="checkSavePassword"
                      >บันทึกข้อมูล</argon-button
                    >
                  </div>
                </div>
              </div>
            </form>
            </div>
          </div>

          <div class="px-0 pb-0 card-body">
            <!--table-->
            <div class="table-responsive">
              <table id="users-list" class="table mb-0 align-items-center">
                <thead>
                  <tr
                    class="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
                  >
                    <th>Username</th>
                    <th>FullName</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tableData.users" :key="item.ID">
                    <td>
                      <div class="px-3 py-1 d-flex">
                        {{ item.Username }}
                      </div>
                    </td>
                    <td>
                      <p class="text-left mb-0 text-sm">
                        {{ item.FullName }}
                      </p>
                    </td>
                    <td class="text-sm">
                      <a
                        href="javascript:;"
                        class="mx-3"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Edit"
                        @click.prevent="
                          handleEdit(item.ID, item.Username, item.FullName)
                        "
                      >
                        <i class="fas fa-user-edit text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-between px-4 py-1">
              <div>
                <div class="d-flex justify-content-center">
                  Showing {{ pagination.from }} to {{ pagination.to }} of
                  {{ pagination.total }} entries
                </div>
              </div>
              <div>
                <argon-pagination>
                  <argon-pagination-item-table
                    v-model="pagination.page"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                    @changePage="onChangePage"
                  />
                </argon-pagination>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { ref, onMounted, reactive, toRaw } from "vue"
import ArgonButton from "@/components/ArgonButton.vue"
import ArgonPagination from "@/components/ArgonPagination.vue"
import ArgonPaginationItemTable from "@/components/ArgonPaginationItemTable.vue"
import { useRouter } from "vue-router"
import SearchUsers from "./components/SearchUsers.vue"
import { useForm } from "vee-validate"
import { object, string, lazy } from "yup"
import Swal from "sweetalert2"
import Choices from "choices.js"

export default {
  name: "ResetPassword",
  props: {},
  components: {
    ArgonButton,
    ArgonPagination,
    ArgonPaginationItemTable,
    SearchUsers,
  },
  setup(props, ctx) {
    const router = useRouter()
    var pagination = reactive({
      perPageOptions: [50, 100],
      perPage: 50,
      page: 1,
      from: 1,
      to: 1,
      total: 1,
      pageCount: 1,
    })
    var searchQuery = ref("")
    var formSearch = ref(false)
    var isShowEdit = ref(false)
    var NewPassword = ref(null)
    var ConfirmNewPassword = ref(null)
    var isShowAlertNewPassword = ref(false)
    var isShowAlertConfirmNewPassword = ref(false)
    var isShowSearch = ref(true)
    var Username = ref(null)
    var UserID = ref(null)
    var FullName = ref(null)
    onMounted(() => {
      if (document.getElementById("choices-page-options")) {
        var element = document.getElementById("choices-page-options")
        setTimeout(function () {
          new Choices(element, {
            searchEnabled: false,
          })
        }, 1)
        for (var i = 0; i < pagination.perPageOptions.length; i++) {
          element.innerHTML +=
            '<option value="' +
            pagination.perPageOptions[i] +
            '">' +
            pagination.perPageOptions[i] +
            "</option>"
        }
        element.addEventListener("change", (event) => {
          onChangePageOptions(event.target.value)
        })
      }
      fetchResult()
    })

    function onChangePage(toPage) {
      pagination.page = toPage
      pagination.from = (toPage - 1) * pagination.perPage + 1
      pagination.to = pagination.from + pagination.perPage - 1
      fetchResult()
    }

    function onChangePageOptions(perPage) {
      //i'm here
      pagination.page
      pagination.perPage = parseInt(perPage)
      pagination.from = (pagination.to - 1) * pagination.perPage + 1
      // pagination.from = (pagination.page - 1) * pagination.perPage + 1
      pagination.to = pagination.from + pagination.perPage - 1
      fetchResult()
    }

    function handleSearch() {
      fetchResult()
    }

    function unAuthorized() {
      localStorage.removeItem("curAuthen")
      localStorage.removeItem("perms")
      router.push("/login")
    }

    let isInvalidSubmit = false
    function onInvalidSubmit({ values, errors, results }) {
      isInvalidSubmit = true
    }

    function clear() {
      NewPassword.value = ""
      ConfirmNewPassword.value = ""
      Username.value = ""
      UserID.value = ""
      FullName.value = ""
      isShowEdit.value = false
      isShowSearch.value = true
    }

    var search = reactive({
      Username: "",
      FullName: "",
    })

    function handleEdit(index, username, fullname) {
      if (index) {
        isShowEdit.value = true
        isShowSearch.value = false
        Username.value = username
        UserID.value = index
        FullName.value = fullname
      }
    }

    function checkNewPassword() {
      if (NewPassword.value == null || NewPassword.value == "") {
        isShowAlertNewPassword.value = true
        return false
      } else {
        if (NewPassword.value.length < 6) {
          isShowAlertNewPassword.value = true
          return false
        } else if (NewPassword.value.length >= 50) {
          isShowAlertNewPassword.value = true
          return false
        } else {
          isShowAlertNewPassword.value = false
          return true
        }
      }
    }

    function checkConfirmNewPasswork() {
      if (ConfirmNewPassword.value == null || ConfirmNewPassword.value == "") {
        isShowAlertConfirmNewPassword.value = true
        return false
      } else {
        if (ConfirmNewPassword.value != NewPassword.value) {
          isShowAlertConfirmNewPassword.value = true
          return false
        } else {
          isShowAlertConfirmNewPassword.value = false
          return true
        }
      }
    }

    function checkSavePassword() {
      checkNewPassword()
      checkConfirmNewPasswork()
      if (checkNewPassword() && checkConfirmNewPasswork()) {
        saveResetPassword()
      }
    }

    function focusOnNewPassword() {
      const el = document.getElementById("NewPassword")
      el.focus()
    }
    const { handleSubmit } = useForm({
      validationSchema: schema,
    })
    const schema = lazy(() =>
      object({
        NewPassword: string(),
        // .required(() => t("errors.required", { field: t("NewPassword") }))
        // .min(18, t("errors.min", { length: 18 }))
        // .max(50, t("errors.max", { length: 50 })),
      })
    )

    const saveResetPassword = handleSubmit((values) => {
      const token = JSON.parse(localStorage.getItem("curAuthen")).token
      const uid = JSON.parse(localStorage.getItem("curAuthen")).uid
      if (UserID.value != null) {
        const myObjects = {
          AuthUserID: Number(UserID.value),
          Password: NewPassword.value,
          UpdatedBy: uid,
        }
        let res = axios({
          method: "post",
          url: "passUpdate",
          data: myObjects,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => {
            if (response.status == 226) {
              showAlertError()
              focusOnNewPassword()
            } else {
              showAlertSuccess()
              clear()
            }
            return
          })
          .catch((error) => {
            if (error.response.status === 401) {
              unAuthorized()
              return
            }
            showAlertError()
            return false
          })
          .then((rs) => {
            return rs // for await purpose
          })
        return res
        //save
      }
    }, onInvalidSubmit)

    var tableData = reactive({
      users: [],
    })
    async function getDataUser() {
      let TOKEN = JSON.parse(localStorage.getItem("curAuthen")).token

      var PageOffset = 0
      if (pagination.page > 1) {
        PageOffset = pagination.page * pagination.perPage
      }
      var objSearch = {
        PageOffset: PageOffset,
        PageSize: pagination.perPage,
        SearchedData: searchQuery.value,
        Username: search.Username,
        FullName: search.FullName,
      }
      let res = await axios({
        method: "post",
        url: "usersSearch",
        data: objSearch,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + TOKEN,
        },
      })
        .then((response) => {
          tableData.users = []
          var dataRes = []
          var page = response.data.paginate
          if (response.data.data) {
            response.data.data.forEach((element) => {
              dataRes = element
              dataRes.status = "Off"
              dataRes.statusType = "danger"
              if (element.IsActive === 1) {
                dataRes.status = "On"
                dataRes.statusType = "success"
              }
              tableData.users.push(dataRes)
            })
          }
          pagination.from = page.RowFrom
          pagination.to = page.RowTo
          pagination.total = page.RowTotal
          pagination.pageCount = Math.ceil(page.RowTotal / pagination.perPage)
          return true
        })
        .catch((error) => {
          if (error.response.status === 401) {
            unAuthorized()
            return
          }
          // failed
          //-> prepare, notify, handle error
          return false // pass to finish
        })
        .then((rs) => {
          // do something after success or error
          return rs // for await purpose
        })
      return res
    } //end getDataUser
    function showAlertError() {
      Swal.fire("Error!", "เกิดข้อผิดพลาด ไม่สามารถทำรายการได้! ", "error")
    }
    function showAlertSuccess() {
      Swal.fire("Success!", "บันทึกข้อมูลเรียบร้อยแล้ว! ", "success")
    }

    async function fetchResult() {
      let success = await getDataUser()
      if (success) {
        // console.log("fetched data success")
        // handle success
      } else {
        // console.log("fetched data failed")
        // handle error
      }
    } //end fetchResult
    return {
      tableData,
      pagination,
      searchQuery,
      search,
      formSearch,
      isShowEdit,
      onChangePage,
      onChangePageOptions,
      handleSearch,
      handleEdit,
      checkSavePassword,
      NewPassword,
      ConfirmNewPassword,
      isShowAlertNewPassword,
      isShowAlertConfirmNewPassword,
      isShowSearch,
      clear,
      Username,
      UserID,
    }
  },
}
</script>
